import { Card, Divider, Space, Statistic, Typography } from "antd";
import React from "react"
import { WholesaleItem } from "../../types/WholesaleItem";

import './Popover.scss';

const CELL_COLORS = {
    'BLUE': '#97f2f3',
    'YELLOW': '#fcf0cf',
    'GREEN': '#D7e7a9',
    'RED': '#FA6E4F',
}

const ProfitPopover: React.FC<WholesaleItem> = (record: WholesaleItem) => {

    const profitStyle = {border: '4px solid', borderRadius: '10px', padding: '3px', borderColor: 'white'};
    const roiStyle = {border: '4px solid', borderRadius: '10px', padding: '3px', borderColor: 'white'};

    if (record.ROI2 < 20) {
        roiStyle.borderColor = CELL_COLORS.RED
    } else if (record.ROI2 < 35) {
        roiStyle.borderColor = CELL_COLORS.YELLOW
    } else {
        roiStyle.borderColor = CELL_COLORS.GREEN
    }

    if ((record.productGroup === 'Shoes' && record.Profit > 5) || (record.productGroup !== 'Shoes' && record.Profit > 2.5) ) {
        profitStyle.borderColor = CELL_COLORS.GREEN
    } else {
        profitStyle.borderColor = CELL_COLORS.RED
    }

    if (record.currentBuyBoxPrice <= 0) {
        profitStyle.borderColor = CELL_COLORS.BLUE
        roiStyle.borderColor = CELL_COLORS.BLUE
    }

    return (
        <Card size="small">
            <Space align="center" direction="horizontal" style={{display: 'flex', justifyContent: 'space-around'}}>
                <Statistic title="Sell Price:" value={record.sellPrice}  precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Referral Fee:" value={record.sellPrice * 0.15} precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="FBA Fee:" value={record.Fees > 0 ? record.Fees : 5}  precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Ship Fee:" value={record.shippingCost}  precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> - </Typography>
                <Divider></Divider>
                <Statistic title="Cost:" value={record.cost} precision={2} prefix={'$'}/>
                <Divider></Divider>
                <Typography> = </Typography>
                <Divider></Divider>
                <Statistic style={profitStyle} title="Profit:" value={record.Profit} precision={2} prefix={'$'} />
                <Divider></Divider>
                <Statistic style={roiStyle} title="ROI:" value={record.ROI2} precision={0} suffix={'%'}/>
            </Space>
        </Card>
    )
}

export default ProfitPopover;