import { Divider, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { CSVDownload } from "../CSVDownload";
import { getNumberFilterSorter } from "../utilities/TableFilterSorters";
import ReactDragListView from 'react-drag-listview';
import Button from "antd/es/button";
const { Link } = Typography;

const BrandTable: React.FC<{ data: any[], collection: string }> = ({ data, collection }) => {
    const [csvData, setCSVData] = useState(data)
    const [columns, setColumns] = useState<ColumnsType<any>>([])
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([])

    useEffect(() => {
        setCSVData(data)
    }, [data])

    useEffect(() => {
        const localColumns: ColumnsType<any> = [
            {
                title: 'ASIN',
                dataIndex: 'parentASIN',
                key: 'parentASIN',
                width: "105px",
                fixed: "left",
                render: (text: string, record: any) => (
                    <Link href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                        {text}
                    </Link>
                ),
            },
            {
                title: 'Title',
                dataIndex: 'Title',
                key: 'Title',
                width: "175px",
            },
            {
                title: 'Parent',
                dataIndex: 'parent',
                key: 'parent',
                width: "80px",
                render: (val) => val ? 'True' : 'False'
            },
            {
                title: 'All Vars',
                dataIndex: 'All',
                key: 'All',
                width: "95px",
                ...getNumberFilterSorter('All')
            },
            {
                title: 'Stocked Vars',
                dataIndex: 'Stocked',
                key: 'Stocked',
                width: "110px",
                ...getNumberFilterSorter('Stocked')
            },
            {
                title: 'Stock %',
                dataIndex: 'StockedPercentage',
                key: 'StockedPercentage',
                width: "90px",
                ...getNumberFilterSorter('StockedPercentage')
            },
            {
                title: 'calcRank',
                dataIndex: 'calcRank',
                key: 'calcRank',
                width: "120px",
                ...getNumberFilterSorter('calcRank'),
                render: (val) => val.toLocaleString()
            },
            {
                title: 'AvgVarPrice',
                dataIndex: 'AveragePrice',
                key: 'AveragePrice',
                width: "135px",
                ...getNumberFilterSorter('AveragePrice'),
                render: (val) => val.toLocaleString()
            },
            {
                title: 'AvgVarSales',
                dataIndex: 'AverageSales',
                key: 'AverageSales',
                width: "135px",
                ...getNumberFilterSorter('AverageSales'),
                render: (val) => val.toLocaleString()
            },
            {
                title: 'TotalSales',
                dataIndex: 'TotalSales',
                key: 'TotalSales',
                width: "120px",
                ...getNumberFilterSorter('TotalSales'),
                render: (val) => val.toLocaleString()
            },
            {
                title: 'AvgValue',
                dataIndex: 'AverageValue',
                key: 'AverageValue',
                width: "120px",
                ...getNumberFilterSorter('AverageValue'),
                render: (val) => val.toLocaleString()
            },
            {
                title: 'TotalValue',
                dataIndex: 'TotalValue',
                key: 'Value',
                width: "120px",
                ...getNumberFilterSorter('TotalValue'),
                render: (val) => val.toLocaleString()
            },
            {
                title: 'MostOftenSeller',
                dataIndex: 'MostOftenSeller',
                key: 'MostOftenSeller',
                width: "130px",
                render: (val) => <a rel="noopener noreferrer" target="_blank" href={`https://www.amazon.com/sp?_encoding=UTF8&asin=B07MMBLY9X&isAmazonFulfilled=1&ref_=olp_merch_name_16&seller=${val[0]}`}>{val[0]}</a>
            },
        ]

        setColumns(localColumns)
    }, [setColumns])

    const dragProps = {
        onDragEnd(fromIndex: any, toIndex: any) {
            const localColumns: any = [...columns as any];
            const item = localColumns.splice(fromIndex, 1)[0];
            localColumns.splice(toIndex, 0, item);
            setColumns(localColumns)
        },
        nodeSelector: "button"
    };

    return (
        <>
            <ReactDragListView.DragColumn {...dragProps}>
                <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                    {columns.map(column => {
                        if (hiddenColumns.includes(column.title as string)) {
                            return <Button key={column.title as string} ghost onClick={() => setHiddenColumns((hidden) => hidden.filter(col => col !== column.title))}>{column.title}</Button>
                        } else {
                            return <Button key={column.title as string} type="primary" onClick={() => setHiddenColumns((hidden) => hidden.concat([column.title as string]))}>{column.title}</Button>
                        }
                    })}
                </div>
            </ReactDragListView.DragColumn>
            <Divider></Divider>
            <Table<any>
                columns={columns.filter((column) => !hiddenColumns.includes(column.title as string))}
                dataSource={data}
                bordered={true}
                size="small"
                scroll={{ y: "88vh", x: "max-content" }}
                sticky={true}
                rowKey={"UPC" + Math.floor(Math.random()* 10000)}
                title={() => <CSVDownload data={csvData} isLoading={false} collection={collection}/>}
                tableLayout={"fixed"}
                onChange={(pagination, filters, sorter, extra) => {
                    setCSVData(extra.currentDataSource)
                }}
            />
        </>
    );
};

export default BrandTable;
