import { WholesaleItem } from "../../types/WholesaleItem";
import { findSales } from "../brand/AnalyzeData";

const FEE_PLACEHOLDER = 5

const GMROI = [
    {
        'ROI': 25,
        'Days': 30,
    },
    {
        'ROI': 27.27,
        'Days': 33,
    },
    {
        'ROI': 30,
        'Days': 36,
    },
    {
        'ROI': 33.33,
        'Days': 40,
    },
    {
        'ROI': 37.50,
        'Days': 46,
    },
    {
        'ROI': 42.86,
        'Days': 52,
    },
    {
        'ROI': 50,
        'Days': 61,
    },
    {
        'ROI': 60,
        'Days': 73,
    },
    {
        'ROI': 75,
        'Days': 91,
    },
    {
        'ROI': 100,
        'Days': 122,
    },
    {
        'ROI': 150,
        'Days': 182,
    },
    {
        'ROI': 300,
        'Days': 365,
    },
]

const MINIMUM_PROFT = (group: string) => {
    switch(group) {
        case 'Shoes': 
            return 5;
        default:
            return 2.5;
    }
} 


export const RecalculateProfits = (row: WholesaleItem) => {
    // Ensure we're still working on numbers
    row.cost = parseFloat(row.cost as unknown as string)
    row.BuyQTY = parseFloat(row.BuyQTY as unknown as string)
    row.AskPrice = parseFloat(row.AskPrice as unknown as string)
    row.sellPrice = parseFloat(row.sellPrice as unknown as string)


    // Re-calculate ROI
    if (row.Fees !== -1 && row.sellPrice !== -1 && row.AskPrice !== -1) {
        row.ROI = parseFloat(((row.sellPrice * 0.85 - row.AskPrice - row.Fees) / row.AskPrice * 100).toFixed(0));
        row.ROI2 = parseFloat(((row.sellPrice * 0.85 - row.AskPrice - row.shippingCost - row.Fees) / row.AskPrice * 100).toFixed(0));
        row.Profit = parseFloat(((row.sellPrice * 0.85 - row.shippingCost - row.Fees) - row.AskPrice).toFixed(2));
    } else if (row.sellPrice !== -1 && row.AskPrice !== -1) {
        row.ROI = parseFloat(((row.sellPrice * 0.85 - row.AskPrice - FEE_PLACEHOLDER) / row.AskPrice * 100).toFixed(0));
        row.ROI2 = parseFloat(((row.sellPrice * 0.85 - row.AskPrice - row.shippingCost - FEE_PLACEHOLDER) / row.AskPrice * 100).toFixed(0));
        row.Profit = parseFloat(((row.sellPrice * 0.85 - row.shippingCost - FEE_PLACEHOLDER) - row.AskPrice).toFixed(2));
    } else {
        row.ROI = -2;
        row.ROI2 = -2;
        row.Profit = -2;
    }
}

export const FindBuyQTY = (row: WholesaleItem) => {
    if (row.ROI2 >= 25 && MINIMUM_PROFT(row.productGroup) < row.Profit) {
        const lowerBoundary = GMROI.find(pred => pred.ROI > row.ROI2)
        const higherBoundary = [...GMROI].reverse().find(pred => pred.ROI <= row.ROI2)
    
        if (lowerBoundary && higherBoundary) {
            row.DaysToBuy = lowerBoundary.Days + (row.ROI2 - lowerBoundary.ROI) * ((higherBoundary.Days - lowerBoundary.Days) / (higherBoundary.ROI - lowerBoundary.ROI));
        } else {
            row.DaysToBuy = lowerBoundary?.Days || higherBoundary?.Days;
        }
    } else {
        row.DaysToBuy = -1;
    }

    if (row.DaysToBuy > 0 && row.EstSales! > 0) {
        if (row.InvQuantity && row.InvQuantity > 0) {
            row.SuggestedQTY = Math.round(row.EstSales! / 30 * row.DaysToBuy - 0.75 * row.InvQuantity);
        } else {
            row.SuggestedQTY = Math.round(row.EstSales! / 30 * row.DaysToBuy);
        }
    } else {
        row.SuggestedQTY = 0;
    }
}

export const FindSellPrice = (row: WholesaleItem) => {
    row.sellPrice = -1;

    if (row.buyBox30Avg > 0 && row.currentBuyBoxPrice > 0) {
        row.sellPrice = (row.buyBox30Avg + row.currentBuyBoxPrice) / 2
    } else if (row.buyBox30Avg > 0) {
        row.sellPrice = row.buyBox30Avg;
    } else if (row.buyBox90Avg > 0) {
        row.sellPrice = row.buyBox90Avg;
    } else if (row.currentBuyBoxPrice > 0) {
        row.sellPrice = row.currentBuyBoxPrice;
    } else{
        row.sellPrice = -1;
    }
}

export const FindEstSales = (row: WholesaleItem & {calcRank: number | undefined}) => {
    row.EstSalesTotal = findSales(row);
    if (row.reviewMultiplier < 1) {
        row.EstSales = row.EstSalesTotal! > -1 ? row.EstSalesTotal! * row.bbMultiplier * row.reviewMultiplier : -1
    } else {
        row.EstSales = row.EstSalesTotal! > -1 ? row.EstSalesTotal! / row.VariationCount * row.bbMultiplier * row.colorMultiplier * row.sizeMultiplier * row.reviewMultiplier : -1;
    }
}

export const FindSales = (row: WholesaleItem) => {
    if (!row.calcRank || row.calcRank === -1) {
        let calcRank = -1

        if (row.currentSalesRank > 0 && row.salesRank30Avg > 0) {
            calcRank = (row.currentSalesRank + row.salesRank30Avg) / 2;
        } else if (row.currentSalesRank > 0) {
            calcRank = row.currentSalesRank;
        } else if (row.salesRank30Avg > 0) {
            calcRank = row.salesRank30Avg;
        }
    
        row.calcRank = calcRank;
    }
}