import React, { useState } from "react";
import { Button, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { resetNotifs } from "../services/ProductsService";

const ResetModal: React.FC<{onReset: () => void}> = ({ onReset }) => {
  const [visible, setVisibility] = useState<boolean>(false);
  return (
    <>
      <Button
        danger
        size="large"
        type="primary"
        icon={<DeleteOutlined />}
        onClick={() => setVisibility(true)}
      >
        Reset
      </Button>
      <Modal
        title="Reset notifications?"
        visible={visible}
        okButtonProps={{ danger: true }}
        onCancel={() => setVisibility(false)}
        onOk={() => {
          setVisibility(false);
          resetNotifs();
          onReset();
        }}
      >
        Notifications will be deleted permanently.
      </Modal>
    </>
  );
};

export default ResetModal;
