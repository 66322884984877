import { Button, Card, Col, Divider, Row, Space, Statistic, Typography, Select } from "antd";
import React, { useEffect, useState } from "react"
import { updateProduct } from "../../services/WholesaleService";
import { WholesaleItem } from "../../types/WholesaleItem";
import './Popover.scss';

const { Text } = Typography;
const { Option } = Select;

const EstSalesPopover: React.FC<{record: WholesaleItem, handleSave: any}> = ({record, handleSave}) => {
    const [bbMultiplier, setBbMultiplier] = useState(record.bbMultiplier.toString())
    const [reviewMultiplier, setReviewMultiplier] = useState(record.reviewMultiplier.toFixed(2))
    const [calcRank, setCalcRank] = useState(record.calcRank.toFixed(0))
    
    useEffect(() => {
        setBbMultiplier(record.bbMultiplier.toString())
        setReviewMultiplier(record.reviewMultiplier.toFixed(2))
        setCalcRank(record.calcRank.toFixed(0))
    }, [record])

    const handleBBSave = (val: string) => {
        if (Number.isNaN(parseFloat(val))) {
            return
        }
        record.bbMultiplier = parseFloat(val)
        setBbMultiplier(parseFloat(val).toString())
        handleSave(record);
    }

    const handleReviewSave = (val: string) => {
        if (Number.isNaN(parseFloat(val))) {
            return
        }
        record.reviewMultiplier = parseFloat(val)
        setReviewMultiplier(parseFloat(val).toString())
        handleSave(record);
    }

    const handleCalcRankSave = (val: string) => {
        if (Number.isNaN(parseFloat(val))) {
            return
        }
        record.calcRank = parseInt(val)
        setCalcRank(parseInt(val).toString())
        handleSave({...record, editSiblings: true});
    }

    const handleGrab = () => {
        record.variationChecked = true;
        updateProduct([record]);
        handleSave(record)
    }

    const handleEnd = () => {
        handleSave(record);
    }

    return (
        <Space data-asin={record.ASIN} direction="horizontal">
            <Card size="small">
                <Row gutter={8}>
                    <Col span={12}>
                        {/* <Statistic title="Calculated Rank:" value={record.calcRank} /> */}
                        <Statistic title="Calculated Rank:" value={calcRank} formatter={(val) => <Text editable={{
                            icon: false,
                            tooltip: `Set the item's calcRank`,
                            onChange: handleCalcRankSave,
                            onEnd: handleEnd,
                            onCancel: handleEnd,
                        }}>{val}</Text>}/>
                    </Col>
                    <Col span={12}>
                        <Statistic title="Current Rank:" value={record.currentSalesRank} />
                    </Col>
                </Row>
                <Divider></Divider>
                <Row gutter={8}>
                    <Col span={12}>
                        <Statistic title="SalesRank (30 days):" value={record.salesRank30Avg} />
                    </Col>
                    <Col span={12}>
                        <Statistic title="SalesRank (90 days):" value={record.salesRank90Avg} />
                    </Col>
                </Row>
            </Card>
            <Card size="small">
                <Row gutter={8}>
                    <Col span={16}>
                        <Statistic title="Category:" value={`${record.rootCategory}`} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Variations:" value={record.VariationCount} precision={0} />
                    </Col>
                </Row>
                <Divider></Divider>
                <Row gutter={8}>
                    <Col span={16}>
                        <Statistic title="BB ×:" value={bbMultiplier} formatter={(val) => 
                            <Select size={"small"} bordered={false} style={{width: '65px'}} defaultValue={`${(parseFloat(bbMultiplier) * 100).toFixed(0)}%`} onChange={handleBBSave}>
                                <Option value={0.25}>25%</Option>
                                <Option value={0.50}>50%</Option>
                                <Option value={0.75}>75%</Option>
                                <Option value={1}>100%</Option>
                            </Select>
                        }/>
                    </Col>
                    <Col span={8}>
                        <Statistic title="Review ×:" value={reviewMultiplier} formatter={(val) => <Text editable={{
                            icon: false,
                            tooltip: 'Set the variation\'s review multiplier',
                            onChange: handleReviewSave,
                            onEnd: handleEnd,
                            onCancel: handleEnd,
                        }}>{val}</Text>}/>
                    </Col>
                </Row>
            </Card>
            <Card size="small">
                <Row gutter={8}>
                    <Col span={16}>
                        <Statistic title="Total Reviews:" value={record.variationReviewsTotal} precision={0} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Active:" value={record.variationActive} precision={0} />
                    </Col>
                </Row>
                <Divider></Divider>
                <Row gutter={8}>
                    <Col span={12}>
                        <Statistic title="Reviews:" value={record.variationReviews} precision={0} />
                    </Col>
                    <Col span={12}>
                        <Button id="wholesale-popover-button" onClick={handleGrab} data-asin={record.ASIN}>Grab</Button>
                    </Col>
                </Row>
            </Card>
            <Card size="small">
                <Statistic title="Total Est. Sales:" value={record.EstSalesTotal} precision={2} />
                <Divider></Divider>
                <Statistic title="Variation's Est. Sales:" value={record.EstSales} precision={2} />
            </Card>
        </Space>
    )
}

export default EstSalesPopover;