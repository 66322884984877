import { MinMaxFilterDropdown } from "../MinMaxFilterDropdown"
import React from "react"
import { Popover, Typography } from "antd"
import SellPricePopover from "../wholesale/SellPricePopover"
import { WarningFilled } from "@ant-design/icons"

export const getNumberFilterSorter = key => {
    return {
        sorter: {
            compare: (a, b) => {
                const localA = a[key] ? a[key] : -1
                const localB = b[key] ? b[key] : -1
                return localA - localB
            },
            multiple: 1
        },
        filterDropdown: (props) => (
            <MinMaxFilterDropdown {...props} defaultMin={0} defaultMax={0} />
        ),
        onFilter: (value, record) => {
            const [min, max] = value.split(" ").map(val => parseFloat(val));
            return (record[key] >= min && (max === 0 || record[key] <= max)) || record.recentlyEdited
        },
    }
}

export const getBooleanFilter = key => {
    return {
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },
        ],
        filterMultiple: false,
        onFilter: (value, record) => (record[key] === value) || record.recentlyEdited
    }
}

export const getPriceRenderer = () => {
    return {
        render: val => {
            return val === undefined || val === -1 || val === null ? '?' : `$${val.toFixed(2)}`
        },
    }
}

export const getSellPriceRenderer = () => {
    return {
        render: (val, record) => {
            const prices = [record.currentBuyBoxPrice, record.buyBox30Avg, record.buyBox90Avg] 
            const minBB = Math.min(...prices)
            const maxBB = Math.max(...prices)

            return <Popover 
                id="wholesale-popover"
                content={SellPricePopover(record)} 
                trigger="hover" 
                title={maxBB / minBB >= 1.3 ? 
                <><WarningFilled style={{color: 'red'}} /> High variance detected <WarningFilled style={{color: 'red'}} /></> 
                : 
                null}
            >
                <div>
                    <Typography>
                        {val === undefined || val === -1 ? 'Suppressed' : `$${val.toFixed(2)}`} {
                            (record.currentBuyBoxPrice === -1 || maxBB / minBB >= 1.3 || record.buyBox30Avg === undefined || record.buyBox90Avg === undefined) && val !== -1
                            ? 
                            <WarningFilled style={{color: 'red'}} /> 
                            : null
                        }
                    </Typography>
                </div>
            </Popover>
        },
    }
}

export const getShareRenderer = () => {
    return {
        render: val => val === undefined || val === -1 ? 'Unknown' : `${val}%`,
    }
}

export const getNumberRenderer = () => {
    return {
        render: val => val === undefined || val === -1 ? '?' : `${val}`,
    }
}
