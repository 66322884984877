import React from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import { Moment } from "moment";

export interface DateRangeFilterDropdownProps extends FilterDropdownProps {
  dateRange?: [Moment, Moment];
  setDateRange: (values?: [Moment, Moment]) => void;
}

export function DateRangeFilterDropdown(props: DateRangeFilterDropdownProps) {
  return (
    <div style={{ padding: 8 }}>
      <DatePicker.RangePicker
        value={props.dateRange}
        onChange={(values) => props.setDateRange(values as [Moment, Moment])}
        locale={locale}
        format="YYYY/DD/MM"
      />
      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={() => {
          props.setSelectedKeys([Math.random()]);
          props.confirm({closeDropdown: true});
        }}
      >
        Search
      </Button>
      <Button
        onClick={() => {
          props.setDateRange(undefined);
          props.clearFilters?.();
        }}
      >
        Reset
      </Button>
    </div>
  );
}
