import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

const CollectionsDropdown: React.FC<{ onChange: ((value: any) => void), getCollections: ((value: any) => Promise<string[]>), queryKey?: string}> = ({ onChange, getCollections, queryKey = 'default' }) => {
  const { data } = useQuery(["collections", queryKey], getCollections);
  
  return (
    <Select defaultValue="Choose site..." onChange={onChange} style={{width:"100%"}}>
      {data?.sort().map((collection, i) => (
        <Select.Option value={collection} key={i}>
          {collection.toUpperCase()}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CollectionsDropdown;
