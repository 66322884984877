import * as dataForge from 'data-forge'
import { mainSalesRankCorrelation } from './SalesRankConstants.mjs';

/**
 * Linear interpolation based on provided rank and category information
 * @param {{calcRank, rootCategory}} item  
 * @returns estimated sales of an item
 */
export function findSales({calcRank, rootCategory}) {
    if (calcRank > 0) {
        const SalesRankCorrelation = mainSalesRankCorrelation[rootCategory] ? mainSalesRankCorrelation[rootCategory] : mainSalesRankCorrelation.default
        const lowerBoundary = SalesRankCorrelation.find(pred => pred.Rank > calcRank)
        const higherBoundary = [...SalesRankCorrelation].reverse().find(pred => pred.Rank <= calcRank)
    
        if (lowerBoundary && higherBoundary) {
            return lowerBoundary.Sales + (calcRank - lowerBoundary.Rank) * ((higherBoundary.Sales - lowerBoundary.Sales) / (higherBoundary.Rank - lowerBoundary.Rank))
        } else {
            return lowerBoundary?.Sales || higherBoundary?.Sales
        }
    } else {
        return -1
    }
}

/**
 * 
 * @param {dataForge.DataFrame} keepaFrame 
 */
function processFrame(keepaFrame) {
    const handlePrice = (price) => {
        price = price.replace('$', '').replace(',', '').replace('-', '');
        if (price) {
            return parseFloat(price);
        } else {
            return -1;
        }
    };

    const handleRank = (rank) => {
        if (rank) {
            return parseInt(rank);
        } else {
            return -1;
        }
    };

    const priceColumns = [
        'New: Current',
        'Amazon: Current',
        'List Price: Current',
        'FBA Pick&Pack Fee',
        'Buy Box 🚚: Current',
    ];

    const rankColumns = [
        'Sales Rank: Current',
        'Sales Rank: 90 days avg.',
        'Reviews: Review Count',
        'Package: Weight (g)',
    ];

    const resultFrame = keepaFrame
        .dropSeries(['"Locale"', 'Locale', 'Color', 'Size', 'Model'])
        .transformSeries({
            ...priceColumns.reduce((prev, curr) => ({ ...prev, [curr]: (val) => handlePrice(val) }), {}),
            ...rankColumns.reduce((prev, curr) => ({ ...prev, [curr]: (val) => handleRank(val) }), {}),
            'Product Codes: UPC': (val) => val.split(', '),
            'Variation ASINs': (val) => val.split(','),
            'Buy Box Seller': (val) => val !== '' ? (val.includes('Party') ? val.split('Party')[1].trim().replace(/\(|\)|\?/g, '') : val) : ''
        })
        .generateSeries({
            'VariationCount': (row) => row['Variation ASINs'].length,
        })
        .renameSeries({
            'Product Codes: UPC': 'UPC',
            'Title': 'name',
            'New: Current': 'currentNewPrice',
            'New: 90 days avg.': 'new90Avg',
            'Amazon: 90 days avg.': 'amazon90Avg',
            'New Offer Count: Current': 'newOfferCount',
            'Sales Rank: Current': 'currentSalesRank',
            'Sales Rank: 90 days avg.': 'salesRank90Avg',
            'Used: 30 days avg.': 'used30Avg',
            'Reviews: Review Count': 'reviewCount',
            'Amazon: Current': 'currentAmazonPrice',
            'List Price: Current': 'currentListPrice',
            'FBA Pick&Pack Fee': 'Fees',
            'Buy Box 🚚: Current': 'currentBuyBoxPrice',
            'Parent ASIN': 'parentASIN',
            'Variation ASINs': 'variationsASIN',
            'Categories: Root': 'rootCategory',
            'Package: Weight (g)': 'weight',
            'Buy Box Seller': 'bbSeller',
            'Brand': 'brand',
        }).bake();

    const renameSpec = {
        // 'Amazon: 90 days avg.': 'amazon90Avg',
    }

    resultFrame.getColumnNames().forEach(column => {
        if (column !== column.replace(/\./g, '')) {
            renameSpec[column] = column.replace(/\./g, '')
        }
    })

    const renamedFrame = resultFrame.renameSeries(renameSpec)
    return renamedFrame
}

export function analyzeBrand(data) {
    const sellerObject = {} // sellerId: bbsOwned
    const stockedObject = {
        All: 0,
        Stocked: 0,
    }
    let totalBrandValue = 0

    const brandFrame = processFrame(dataForge.fromCSV(data))
        .forEach(row => {
            stockedObject.All++;
            if (row['newOfferCount'] !== '') stockedObject.Stocked++;

            if (row.bbSeller !== '') {
                sellerObject[row.bbSeller] = (sellerObject[row.bbSeller] + 1) || 1
            }
        })
        .generateSeries({
            calcRank: row => row.currentSalesRank === -1 ? (row.salesRank90Avg === -1 ? -1 : row.salesRank90Avg) : row.currentSalesRank,
            lowestPrice: row => {
                const nums = [];
                let lowestPrice = 0;
                if (row.currentBuyBoxPrice !== -1) {
                    lowestPrice = row.currentBuyBoxPrice;
                } else {
                    if (row.currentNewPrice !== -1) {
                        nums.push(row.currentNewPrice);
                    }
                    if (row.currentAmazonPrice !== -1) {
                        nums.push(row.currentAmazonPrice);
                    }
                    if (nums.length >= 1) {
                        lowestPrice = Math.min(...nums);
                    } else {
                        lowestPrice = -1;
                    }
                }
                return lowestPrice
            }
        })

    console.log(brandFrame.toArray())

    const percentageOfStocked = (stockedObject.Stocked / stockedObject.All) * 100

    const mostCommonSellers = []
    Object.entries(sellerObject).forEach(([seller, bbsOwned]) => {
        if (seller !== '') {
            mostCommonSellers.push({
                'SellerId': seller,
                'BBsOwned': bbsOwned,
            })
        }
    })

    mostCommonSellers.sort((a, b) => b.BBsOwned - a.BBsOwned)

    const individualFrame = brandFrame.where(row => row.parentASIN === '')
        .generateSeries({
            TotalSales: (row) => findSales(row),
            AverageSales: (row) => row.TotalSales,
            AveragePrice: (row) => row.lowestPrice,
            All: () => 1,
            Stocked: (row) => row['newOfferCount'] ? 1 : 0,
            StockedPercentage: (row) => row['newOfferCount'] ? 100 : 0,
            MostOftenSeller: (row) => row.bbSeller.length > 0 ? [row.bbSeller, 1] : ['None', 0],
            parentASIN: (row) => row.ASIN,
            parent: (row) => false,
            Title: (row) => row.name,
        })
        .generateSeries({
            TotalValue: row => (row.lowestPrice !== -1 && row.TotalSales !== -1) ? row.lowestPrice * row.TotalSales : -1,
            AverageValue: row => (row.lowestPrice !== -1 && row.TotalSales !== -1) ? row.lowestPrice * row.TotalSales : -1,
        })

    const parentFrame = brandFrame.where(row => row.parentASIN !== '')
        .pivot('parentASIN', {
            'newOfferCount': {
                Stocked: (series) => series.where(val => val !== '').count(),
                All: (series) => series.count(),
            },
            'name': {
                Title: (series) => series.first()
            },
            'calcRank': {
                calcRank: (series) => series.median()
            },
            'lowestPrice': {
                AveragePrice: (series) => parseFloat((series.where(val => val !== -1).sum() / series.where(val => val !== -1).count()).toFixed(2)) || -1
            },
            'rootCategory': {
                rootCategory: (series) => series.first()
            },
            'bbSeller': {
                MostOftenSeller: (series) => {
                    const sellerObj = {}
                    series.forEach(val => sellerObj[val] = (sellerObj[val] + 1) | 1)
                    return Object.entries(sellerObj).sort((a, b) => b[1] - a[1]).find(val => val[0] !== "") || ['None', 0]
                }
            },
            'brand': {
                brand: (series) => series.first()
            }
        })
        .generateSeries({
            StockedPercentage: row => parseFloat(((row.Stocked / row.All) * 100).toFixed(2)),
            TotalSales: (row) => findSales(row),
            parent: (row) => true,
        })
        .generateSeries({
            TotalValue: row => (row.AveragePrice !== -1 && row.TotalSales !== -1) ? row.AveragePrice * row.TotalSales : -1,
            AverageSales: row => row.Stocked > 0 ? row.TotalSales / row.Stocked : -1
        })
        .generateSeries({
            AverageValue: (row) => row.TotalValue !== -1 ? row.TotalValue / row.Stocked || -1 : -1
        })

    const finalFrame = parentFrame.concat(individualFrame)

    totalBrandValue = finalFrame.getSeries('TotalValue').where(val => val !== -1).sum()

    return {
        brand: finalFrame.where(row => row.brand.length > 0).first().brand,
        totalBrandValue: totalBrandValue,
        mostCommonSellers: mostCommonSellers,
        percentageOfStocked: percentageOfStocked,
        parentFrame: finalFrame.toArray().sort((a, b) => b.TotalValue - a.TotalValue)
    }
}