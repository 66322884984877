import { ApiItem, Item } from "../types/Item";
import moment from "moment";
import { ApiKey } from "./constants";

export function getProducts(collection: string, expanded?: boolean): Promise<Item[]> {
  if (expanded) {
    return Promise.all([
      fetch(`https://api.projectsuite.io/managedb/getAll?key=${ApiKey}&db=zappos&collection=${collection}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then((data) => data.result.map((item: any) => ( // I hate TS
          {
            ...item,
            productUrl: collection.includes('zappos') || collection.includes('sixpm') || collection.includes('academy') ? `https://${collection.replace("six", "6")}.com${item.productUrl}` : item.productUrl, 
            timestamp: moment(item.insertTimestamp),
            morning: moment(item.insertTimestamp).hour() < 10,
            originalPrice: parseFloat(item.originalPrice.slice(1)),
            price: parseFloat(item.price.slice(1)),
            percentOff: parseFloat(item.percentOff.slice(0, -1)),
          }
        ))),
      fetch(`https://api.projectsuite.io/keepa/getAll?key=${ApiKey}&minimal=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
    ])
      .then((resSet) => {
        const zapposData = resSet[0];
        const keepaData = resSet[1].result;

        const keepaMap = new Map<string, any>()
        for (const product of keepaData) {
          for (const upc of ([...(product.upcList || []), product.asin])) {
            if (!keepaMap.has(upc)) {
              keepaMap.set(upc, product)
            }
          }
        }

        const finalData = zapposData
          .map((prod: any) => {
            const newProd = {...prod, KeepaData: keepaMap.get(prod.asin || prod.upc)}
            return newProd
          })
          .filter((prod: any) => prod.KeepaData?.stats && prod.KeepaData?.title)
          .map((prod: any) => ({
            ...prod,
            asin: prod.asin || prod.KeepaData.asin,
            name: prod.KeepaData.title,
            salesRank: prod.KeepaData.stats.avg30[3],
            azImage: prod.KeepaData.imagesCSV?.split(',')[0] ? `https://images-na.ssl-images-amazon.com/images/I/` + prod.KeepaData.imagesCSV.split(',')[0] : null,
            amzPrice: prod.KeepaData.stats.avg30[1] / 100
          }))

          
        console.log(finalData)
        return finalData
      })
      .catch((ex) => console.log('ex', ex))
  } else {
    return fetch(
      `https://api.projectsuite.io/sourcing?collection=${collection}&key=${ApiKey}`
    )
      .then((res) => res.json())
      .then((data) => data.result.map((item: ApiItem) => ({ ...item, timestamp: moment(item.timestamp), morning: item.morning || false })));
  }
}

export function getCollections(): Promise<string[]> {
  return fetch(
    `https://api.projectsuite.io/sourcing/collections?key=${ApiKey}`
  )
    .then((res) => res.json())
    .then((data) =>
      data.result
        .map((col: any) => col.name)
        .filter((name: string) => name !== "notifs" && name !== "sierra_alt" && name !== "eastbayps")
    );
}

export function getExpandedCollections(): Promise<string[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(['zappos', 'sixpm', 'academy', 'dickssportinggoods', 'macys']);
    }, 300);
  });
}

export function getNotifs(): Promise<Item[]> {
  return getProducts("notifs");
}

export function resetNotifs() {
  return fetch("https://api.projectsuite.io/sourcing", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      products: [],
      collection: "notifs",
      key: ApiKey,
      reset: true,
    }),
  });
}
