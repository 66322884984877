import { BrandData } from "../types/Brand";
import { ApiKey } from "./constants";

// const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://api.projectsuite.io' 
const BASE_URL = 'https://api.projectsuite.io' 

export function getProducts(collection: string): Promise<BrandData> {
    return fetch(
        `${BASE_URL}/brand?collection=${collection}&key=${ApiKey}`
    )
        .then((res) => res.json())
        .then((res) => res.result)
}

export function getCollections(): Promise<string[]> {
    return fetch(
        `${BASE_URL}/brand/collections?key=${ApiKey}`
    )
        .then((res) => res.json())
        .then((data) =>
            data.result
                .map((col: any) => col.name)
        );
}

export function importProducts(collection: string, brandData: BrandData) {
    return fetch(`${BASE_URL}/brand?collection=${collection}&key=maximumsourcing`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(brandData)
    })
}