import React, { useState, useEffect } from "react";
import DataTable from "../components/DataTable";
import { getNotifs, resetNotifs } from "../services/ProductsService";
import ResetModal from "../components/ResetModal";
import { useQuery, useMutation, queryCache } from "react-query";
import {
  BrandSpecificFilters,
  BrandFilter,
} from "../components/BrandSpecificFilters";
import { Item } from "../types/Item";

const Monitoring: React.FC = () => {
  const { data, isLoading } = useQuery("notifs", getNotifs);
  const [filteredData, setFilteredData] = useState<Item[]>([]);

  const [filters, setFilters] = useState<BrandFilter[]>([]);

  useEffect(() => {
    const filtersStr = window.localStorage.getItem("monitoringFilters");
    if (filtersStr) {
      setFilters(JSON.parse(filtersStr));
    } else {
      setFilters([
        { brand: "Nike", minDiscount: 19 },
        { brand: "", minDiscount: 24 },
      ]);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("monitoringFilters", JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    let appendedFilters = filters;
    if (!filters.find((filter) => filter.brand === ""))
      appendedFilters = [...appendedFilters, { brand: "", minDiscount: 0 }];
    setFilteredData(
      data?.filter((item) =>
        appendedFilters
          .map(({ brand, minDiscount }) =>
            item.name?.toLowerCase().includes(brand.toLowerCase())
              ? item.percentOff > minDiscount
              : true
          )
          .reduce((a, b) => a && b, true)
      ) || []
    );
  }, [filters, data]);

  const [reset] = useMutation(resetNotifs, {
    onSuccess: () => {
      queryCache.invalidateQueries("notifs");
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <BrandSpecificFilters filters={filters} setFilters={setFilters} />
        <ResetModal onReset={reset} />
      </div>
      <DataTable loading={isLoading} data={filteredData} />
    </>
  );
};

export default Monitoring;
