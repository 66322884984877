import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

function checkRole(userRole: string | undefined, desiredRole: string) {
  if (userRole) {
    if (desiredRole === userRole) {
      return true;
    } else if (userRole === 'ADMIN') {
      return true;
    }
  }

  return false;
}

interface PrivateRouteProps extends RouteProps {
    desiredRole: string,
}

export default function PrivateRoute({ desiredRole, children, ...rest } : PrivateRouteProps) {
  const { currentUser, userData } = useAuth();
  if (checkRole(userData?.role, desiredRole) || process.env.NODE_ENV === 'development') {
    return (
      <Route {...rest}>{currentUser ? children : <Redirect to="/login" />}</Route>
    );
  } else if (currentUser) {
    return <Redirect to="/" />
  } else {
    return <Redirect to="/login" />
  }
}
