import React, { KeyboardEvent, MouseEvent, useState } from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export interface MinMaxFilterDropdownProps extends FilterDropdownProps {
  defaultMin: number;
  defaultMax: number;
}

export function MinMaxFilterDropdown(props: MinMaxFilterDropdownProps) {
  const [min, setMin] = useState(props.defaultMin);
  const [max, setMax] = useState(props.defaultMax);

  const pressEnterHandler: (event: KeyboardEvent<HTMLInputElement>) => void = (event: KeyboardEvent<HTMLInputElement>) => {
    props.confirm()
  }

  const pressMouseHandler: (event: MouseEvent<HTMLElement>) => void = (event: MouseEvent<HTMLElement>) => {
    props.confirm()
  }


  return (
    <div style={{ padding: 8 }}>
      <Input
        // ref={props.inputRef}
        placeholder="Min"
        type="number"
        value={min}
        onChange={(e) => {
            setMin(Number(e.target.value))
            props.setSelectedKeys([e.target.value + " " + max]);
        }}
        style={{ width: 188, marginBottom: 8, display: "block" }}
        onPressEnter={pressEnterHandler}
      />
      <Input
        // ref={props.inputRef}
        placeholder="Max"
        type="number"
        value={max}
        onChange={(e) => {
            setMax(Number(e.target.value))
            props.setSelectedKeys([min + " " + e.target.value]);
        }}
        style={{ width: 188, marginBottom: 8, display: "block" }}
        onPressEnter={pressEnterHandler}
      />
      <Space>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
          onClick={(e) => {
            props.setSelectedKeys([min + " " + max])
            pressMouseHandler(e)
          }}
        >
          Search
        </Button>
        <Button onClick={props.clearFilters} style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
}
