import { InventoryItem, WholesaleItem } from "../types/WholesaleItem";
import { ApiKey } from "./constants";
import firebase from 'firebase/app';

const DEVELOPMENT = process.env.NODE_ENV === 'development'
const BASE_URL = DEVELOPMENT ? 'http://localhost:3001' : 'https://api.projectsuite.io' 
export const SUPPLIER_PREFIX = "Supplier_"
// const BASE_URL = 'https://api.projectsuite.io' 

export const compareSupplierValues = (a: WholesaleItem, b: WholesaleItem) => {
    const aSupplierData = Object.fromEntries(Object.entries(a).filter(([key, val]) => key.includes(SUPPLIER_PREFIX)))
    const bSupplierData = Object.fromEntries(Object.entries(b).filter(([key, val]) => key.includes(SUPPLIER_PREFIX)))

    return JSON.stringify(aSupplierData) === JSON.stringify(bSupplierData)
}

export function getProducts(collection: string): Promise<WholesaleItem[]> {
    return fetch(
        `${BASE_URL}/wholesale?collection=${collection}&key=${ApiKey}`
    )
        .then((res) => res.json())
        .then((res) => res.result.map((prod: WholesaleItem) => ({...prod, sourceCollection: collection})))
}

export function getCollections(saved: boolean): Promise<string[]> {
    return fetch(
        `${BASE_URL}/wholesale/collections?key=${ApiKey}${saved ? `&saved=true` : ''}`
    )
        .then((res) => res.json())
        .then((data) =>
            data.result
                .map((col: any) => col.name)
        );
}

export function getCollectionsForUser(saved: boolean, user: firebase.User): Promise<string[]> {
    return user.getIdToken().then((token) =>
        fetch(
            `${BASE_URL}/wholesale/collections?firebase_token=${token}${saved ? `&saved=true` : ''}`
        ))
        .then((res) => res.json())
        .then((data) =>
            data.result
                .map((col: any) => col.name)
        )
}

export function getInventory(token: string): Promise<InventoryItem[]> {
    return fetch(
        `${BASE_URL}/repricer?firebase_token=${token}&collection=inventory&db=luOjCSlnssfwomE0Lic6gHzmDcw1`
    )
        .then((res) => res.json())
        .then((data) =>
            DEVELOPMENT ?
            []
            :
            data.result
                .map((col: any) => ({...col, TotalQuantity: parseInt(col.TotalQuantity)}))
        );
}

export function importProducts(collection: string, formData: FormData) {
    return fetch(`${BASE_URL}/wholesale?collection=${collection}&key=maximumsourcing`, {
            method: 'POST',
            body: formData
    })
}

export function importProductsForUser(collection: string, formData: FormData, token: string) {
    return fetch(`${BASE_URL}/wholesale?collection=${collection}&firebase_token=${token}`, {
            method: 'POST',
            body: formData
    })
}

export function saveProduct(product: WholesaleItem) {
    const body = {
        product: product
    }

    return fetch(`${BASE_URL}/wholesale/save?key=${ApiKey}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    })
}

export function updateProduct(products: WholesaleItem[]) {
    const body = {
        products: products
    }

    return fetch(`${BASE_URL}/wholesale/update?key=${ApiKey}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    })
}

export function deleteCollection(collection: string) {
    return fetch(`${BASE_URL}/wholesale?collection=${collection}&key=maximumsourcing`, {
        method: 'DELETE',
    }).then(res => res.json());
}