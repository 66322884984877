export const mainSalesRankCorrelation = {
  'Clothing, Shoes & Jewelry': [
    {
      'Rank': 500,
      'Sales': 8000,
    },
    {
      'Rank': 1000,
      'Sales': 6000,
    },
    {
      'Rank': 2000,
      'Sales': 4000,
    },
    {
      'Rank': 3000,
      'Sales': 3500,
    },
    {
      'Rank': 4000,
      'Sales': 3000,
    },
    {
      'Rank': 5000,
      'Sales': 2400,
    },
    {
      'Rank': 7500,
      'Sales': 2000,
    },
    {
      'Rank': 10000,
      'Sales': 1600,
    },
    {
      'Rank': 12500,
      'Sales': 1200,
    },
    {
      'Rank': 15000,
      'Sales': 1000,
    },
    {
      'Rank': 17500,
      'Sales': 900,
    },
    {
      'Rank': 20000,
      'Sales': 800,
    },
    {
      'Rank': 25000,
      'Sales': 700,
    },
    {
      'Rank': 30000,
      'Sales': 600,
    },
    {
      'Rank': 35000,
      'Sales': 500,
    },
    {
      'Rank': 40000,
      'Sales': 400,
    },
    {
      'Rank': 45000,
      'Sales': 350,
    },
    {
      'Rank': 50000,
      'Sales': 300,
    },
    {
      'Rank': 55000,
      'Sales': 250,
    },
    {
      'Rank': 60000,
      'Sales': 225,
    },
    {
      'Rank': 65000,
      'Sales': 200,
    },
    {
      'Rank': 70000,
      'Sales': 175,
    },
    {
      'Rank': 75000,
      'Sales': 160,
    },
    {
      'Rank': 80000,
      'Sales': 150,
    },
    {
      'Rank': 85000,
      'Sales': 135,
    },
    {
      'Rank': 90000,
      'Sales': 130,
    },
    {
      'Rank': 95000,
      'Sales': 120,
    },
    {
      'Rank': 100000,
      'Sales': 110,
    },
    {
      'Rank': 110000,
      'Sales': 100,
    },
    {
      'Rank': 120000,
      'Sales': 90,
    },
    {
      'Rank': 130000,
      'Sales': 85,
    },
    {
      'Rank': 140000,
      'Sales': 80,
    },
    {
      'Rank': 150000,
      'Sales': 75,
    },
    {
      'Rank': 160000,
      'Sales': 70,
    },
    {
      'Rank': 170000,
      'Sales': 65,
    },
    {
      'Rank': 180000,
      'Sales': 60,
    },
    {
      'Rank': 190000,
      'Sales': 55,
    },
    {
      'Rank': 200000,
      'Sales': 50,
    },
    {
      'Rank': 210000,
      'Sales': 45,
    },
    {
      'Rank': 220000,
      'Sales': 40,
    },
    {
      'Rank': 230000,
      'Sales': 35,
    },
    {
      'Rank': 240000,
      'Sales': 30,
    },
    {
      'Rank': 250000,
      'Sales': 28,
    },
    {
      'Rank': 260000,
      'Sales': 24,
    },
    {
      'Rank': 270000,
      'Sales': 22,
    },
    {
      'Rank': 280000,
      'Sales': 20,
    },
    {
      'Rank': 290000,
      'Sales': 18,
    },
    {
      'Rank': 300000,
      'Sales': 15,
    },
    {
      'Rank': 350000,
      'Sales': 12,
    },
    {
      'Rank': 400000,
      'Sales': 9,
    },
    {
      'Rank': 500000,
      'Sales': 5,
    },
    {
      'Rank': 750000,
      'Sales': 2.5,
    },
    {
      'Rank': 1000000,
      'Sales': 1,
    },
    {
      'Rank': 1500000,
      'Sales': 0,
    },
  ],
  'Sports & Outdoors': [
    {
        "Rank": 500,
        "Sales": 7110
    },
    {
        "Rank": 1000,
        "Sales": 4590
    },
    {
        "Rank": 2000,
        "Sales": 2850
    },
    {
        "Rank": 3000,
        "Sales": 2070
    },
    {
        "Rank": 4000,
        "Sales": 1650
    },
    {
        "Rank": 5000,
        "Sales": 1380
    },
    {
        "Rank": 7500,
        "Sales": 960
    },
    {
        "Rank": 10000,
        "Sales": 750
    },
    {
        "Rank": 15000,
        "Sales": 480
    },
    {
        "Rank": 20000,
        "Sales": 360
    },
    {
        "Rank": 30000,
        "Sales": 240
    },
    {
        "Rank": 50000,
        "Sales": 120
    },
    {
        "Rank": 75000,
        "Sales": 90
    },
    {
        "Rank": 100000,
        "Sales": 60
    },
    {
        "Rank": 125000,
        "Sales": 45
    },
    {
        "Rank": 150000,
        "Sales": 30
    },
    {
        "Rank": 175000,
        "Sales": 20
    },
    {
        "Rank": 200000,
        "Sales": 10
    },
    {
        "Rank": 225000,
        "Sales": 8
    },
    {
        "Rank": 250000,
        "Sales": 5
    },
    {
        "Rank": 300000,
        "Sales": 3
    },
    {
        "Rank": 500000,
        "Sales": 1
    },
    {
        "Rank": 750000,
        "Sales": 0
    }
  ],
  'Patio, Lawn & Garden': [
    {
        "Rank": 500,
        "Sales": 3210
    },
    {
        "Rank": 1000,
        "Sales": 2070
    },
    {
        "Rank": 2000,
        "Sales": 1290
    },
    {
        "Rank": 3000,
        "Sales": 960
    },
    {
        "Rank": 4000,
        "Sales": 750
    },
    {
        "Rank": 5000,
        "Sales": 630
    },
    {
        "Rank": 7500,
        "Sales": 450
    },
    {
        "Rank": 10000,
        "Sales": 360
    },
    {
        "Rank": 15000,
        "Sales": 240
    },
    {
        "Rank": 20000,
        "Sales": 180
    },
    {
        "Rank": 30000,
        "Sales": 120
    },
    {
        "Rank": 50000,
        "Sales": 60
    },
    {
        "Rank": 75000,
        "Sales": 30
    },
    {
        "Rank": 100000,
        "Sales": 30
    },
    {
        "Rank": 200000,
        "Sales": 0
    },
    {
        "Rank": 250000,
        "Sales": 0
    },
    {
        "Rank": 500000,
        "Sales": 0
    },
    {
        "Rank": 1000000,
        "Sales": 0
    }
  ],
  'Grocery & Gourmet Food': [
    {
        "Rank": 500,
        "Sales": 7950
    },
    {
        "Rank": 1000,
        "Sales": 5310
    },
    {
        "Rank": 2000,
        "Sales": 3330
    },
    {
        "Rank": 3000,
        "Sales": 2430
    },
    {
        "Rank": 4000,
        "Sales": 1920
    },
    {
        "Rank": 5000,
        "Sales": 1560
    },
    {
        "Rank": 7500,
        "Sales": 1050
    },
    {
        "Rank": 10000,
        "Sales": 780
    },
    {
        "Rank": 15000,
        "Sales": 510
    },
    {
        "Rank": 20000,
        "Sales": 360
    },
    {
        "Rank": 30000,
        "Sales": 210
    },
    {
        "Rank": 50000,
        "Sales": 90
    },
    {
        "Rank": 75000,
        "Sales": 60
    },
    {
        "Rank": 100000,
        "Sales": 30
    },
    {
        "Rank": 200000,
        "Sales": 0
    },
    {
        "Rank": 250000,
        "Sales": 0
    },
    {
        "Rank": 500000,
        "Sales": 0
    }
  ],
  'Automotive': [
    {
        "Rank": 500,
        "Sales": 3060
    },
    {
        "Rank": 1000,
        "Sales": 1950
    },
    {
        "Rank": 2000,
        "Sales": 1200
    },
    {
        "Rank": 3000,
        "Sales": 870
    },
    {
        "Rank": 4000,
        "Sales": 720
    },
    {
        "Rank": 5000,
        "Sales": 600
    },
    {
        "Rank": 7500,
        "Sales": 420
    },
    {
        "Rank": 10000,
        "Sales": 330
    },
    {
        "Rank": 15000,
        "Sales": 240
    },
    {
        "Rank": 20000,
        "Sales": 180
    },
    {
        "Rank": 30000,
        "Sales": 120
    },
    {
        "Rank": 50000,
        "Sales": 90
    },
    {
        "Rank": 75000,
        "Sales": 60
    },
    {
        "Rank": 100000,
        "Sales": 30
    },
    {
        "Rank": 200000,
        "Sales": 30
    },
    {
        "Rank": 250000,
        "Sales": 30
    },
    {
        "Rank": 500000,
        "Sales": 0
    },
    {
        "Rank": 1000000,
        "Sales": 0
    }
  ],
  'default': [
    {
      'Rank': 500,
      'Sales': 3000,
    },
    {
      'Rank': 1000,
      'Sales': 2500,
    },
    {
      'Rank': 2000,
      'Sales': 2000,
    },
    {
      'Rank': 3000,
      'Sales': 1750,
    },
    {
      'Rank': 4000,
      'Sales': 1500,
    },
    {
      'Rank': 5000,
      'Sales': 1200,
    },
    {
      'Rank': 7500,
      'Sales': 1000,
    },
    {
      'Rank': 10000,
      'Sales': 800,
    },
    {
      'Rank': 12500,
      'Sales': 600,
    },
    {
      'Rank': 15000,
      'Sales': 500,
    },
    {
      'Rank': 17500,
      'Sales': 450,
    },
    {
      'Rank': 20000,
      'Sales': 400,
    },
    {
      'Rank': 25000,
      'Sales': 350,
    },
    {
      'Rank': 30000,
      'Sales': 300,
    },
    {
      'Rank': 35000,
      'Sales': 250,
    },
    {
      'Rank': 40000,
      'Sales': 200,
    },
    {
      'Rank': 45000,
      'Sales': 175,
    },
    {
      'Rank': 50000,
      'Sales': 150,
    },
    {
      'Rank': 55000,
      'Sales': 125,
    },
    {
      'Rank': 60000,
      'Sales': 100,
    },
    {
      'Rank': 65000,
      'Sales': 80,
    },
    {
      'Rank': 70000,
      'Sales': 70,
    },
    {
      'Rank': 75000,
      'Sales': 60,
    },
    {
      'Rank': 80000,
      'Sales': 50,
    },
    {
      'Rank': 85000,
      'Sales': 40,
    },
    {
      'Rank': 90000,
      'Sales': 30,
    },
    {
      'Rank': 95000,
      'Sales': 25,
    },
    {
      'Rank': 100000,
      'Sales': 22,
    },
    {
      'Rank': 110000,
      'Sales': 20,
    },
    {
      'Rank': 120000,
      'Sales': 18,
    },
    {
      'Rank': 130000,
      'Sales': 15,
    },
    {
      'Rank': 140000,
      'Sales': 12,
    },
    {
      'Rank': 150000,
      'Sales': 10,
    },
    {
      'Rank': 160000,
      'Sales': 8,
    },
    {
      'Rank': 170000,
      'Sales': 7,
    },
    {
      'Rank': 180000,
      'Sales': 6,
    },
    {
      'Rank': 190000,
      'Sales': 5,
    },
    {
      'Rank': 200000,
      'Sales': 5,
    },
    {
      'Rank': 210000,
      'Sales': 4,
    },
    {
      'Rank': 220000,
      'Sales': 4,
    },
    {
      'Rank': 230000,
      'Sales': 3,
    },
    {
      'Rank': 240000,
      'Sales': 3,
    },
    {
      'Rank': 250000,
      'Sales': 2,
    },
    {
      'Rank': 260000,
      'Sales': 2,
    },
    {
      'Rank': 270000,
      'Sales': 2,
    },
    {
      'Rank': 280000,
      'Sales': 2,
    },
    {
      'Rank': 290000,
      'Sales': 2,
    },
    {
      'Rank': 300000,
      'Sales': 2,
    },
    {
      'Rank': 350000,
      'Sales': 2,
    },
    {
      'Rank': 400000,
      'Sales': 1,
    },
    {
      'Rank': 500000,
      'Sales': 1,
    },
    {
      'Rank': 750000,
      'Sales': 0.5,
    },
    {
      'Rank': 1000000,
      'Sales': 0.5,
    },
  ]
}


export const rankSalesCorrelation = [
  {
    'Rank': 500,
    'Sales': 8000,
  },
  {
    'Rank': 1000,
    'Sales': 6000,
  },
  {
    'Rank': 2000,
    'Sales': 4000,
  },
  {
    'Rank': 3000,
    'Sales': 3500,
  },
  {
    'Rank': 4000,
    'Sales': 3000,
  },
  {
    'Rank': 5000,
    'Sales': 2400,
  },
  {
    'Rank': 7500,
    'Sales': 2000,
  },
  {
    'Rank': 10000,
    'Sales': 1600,
  },
  {
    'Rank': 12500,
    'Sales': 1200,
  },
  {
    'Rank': 15000,
    'Sales': 1000,
  },
  {
    'Rank': 17500,
    'Sales': 900,
  },
  {
    'Rank': 20000,
    'Sales': 800,
  },
  {
    'Rank': 25000,
    'Sales': 700,
  },
  {
    'Rank': 30000,
    'Sales': 600,
  },
  {
    'Rank': 35000,
    'Sales': 500,
  },
  {
    'Rank': 40000,
    'Sales': 400,
  },
  {
    'Rank': 45000,
    'Sales': 350,
  },
  {
    'Rank': 50000,
    'Sales': 300,
  },
  {
    'Rank': 55000,
    'Sales': 250,
  },
  {
    'Rank': 60000,
    'Sales': 225,
  },
  {
    'Rank': 65000,
    'Sales': 200,
  },
  {
    'Rank': 70000,
    'Sales': 175,
  },
  {
    'Rank': 75000,
    'Sales': 160,
  },
  {
    'Rank': 80000,
    'Sales': 150,
  },
  {
    'Rank': 85000,
    'Sales': 135,
  },
  {
    'Rank': 90000,
    'Sales': 130,
  },
  {
    'Rank': 95000,
    'Sales': 120,
  },
  {
    'Rank': 100000,
    'Sales': 110,
  },
  {
    'Rank': 110000,
    'Sales': 100,
  },
  {
    'Rank': 120000,
    'Sales': 90,
  },
  {
    'Rank': 130000,
    'Sales': 85,
  },
  {
    'Rank': 140000,
    'Sales': 80,
  },
  {
    'Rank': 150000,
    'Sales': 75,
  },
  {
    'Rank': 160000,
    'Sales': 70,
  },
  {
    'Rank': 170000,
    'Sales': 65,
  },
  {
    'Rank': 180000,
    'Sales': 60,
  },
  {
    'Rank': 190000,
    'Sales': 55,
  },
  {
    'Rank': 200000,
    'Sales': 50,
  },
  {
    'Rank': 210000,
    'Sales': 45,
  },
  {
    'Rank': 220000,
    'Sales': 40,
  },
  {
    'Rank': 230000,
    'Sales': 35,
  },
  {
    'Rank': 240000,
    'Sales': 30,
  },
  {
    'Rank': 250000,
    'Sales': 28,
  },
  {
    'Rank': 260000,
    'Sales': 24,
  },
  {
    'Rank': 270000,
    'Sales': 22,
  },
  {
    'Rank': 280000,
    'Sales': 20,
  },
  {
    'Rank': 290000,
    'Sales': 18,
  },
  {
    'Rank': 300000,
    'Sales': 15,
  },
  {
    'Rank': 350000,
    'Sales': 12,
  },
  {
    'Rank': 400000,
    'Sales': 9,
  },
  {
    'Rank': 500000,
    'Sales': 5,
  },
  {
    'Rank': 750000,
    'Sales': 2.5,
  },
  {
    'Rank': 1000000,
    'Sales': 1,
  },
]

export const rankSalesCorrelationAlt = [
  {
    'Rank': 500,
    'Sales': 3000,
  },
  {
    'Rank': 1000,
    'Sales': 2500,
  },
  {
    'Rank': 2000,
    'Sales': 2000,
  },
  {
    'Rank': 3000,
    'Sales': 1750,
  },
  {
    'Rank': 4000,
    'Sales': 1500,
  },
  {
    'Rank': 5000,
    'Sales': 1200,
  },
  {
    'Rank': 7500,
    'Sales': 1000,
  },
  {
    'Rank': 10000,
    'Sales': 800,
  },
  {
    'Rank': 12500,
    'Sales': 600,
  },
  {
    'Rank': 15000,
    'Sales': 500,
  },
  {
    'Rank': 17500,
    'Sales': 450,
  },
  {
    'Rank': 20000,
    'Sales': 400,
  },
  {
    'Rank': 25000,
    'Sales': 350,
  },
  {
    'Rank': 30000,
    'Sales': 300,
  },
  {
    'Rank': 35000,
    'Sales': 250,
  },
  {
    'Rank': 40000,
    'Sales': 200,
  },
  {
    'Rank': 45000,
    'Sales': 175,
  },
  {
    'Rank': 50000,
    'Sales': 150,
  },
  {
    'Rank': 55000,
    'Sales': 125,
  },
  {
    'Rank': 60000,
    'Sales': 100,
  },
  {
    'Rank': 65000,
    'Sales': 80,
  },
  {
    'Rank': 70000,
    'Sales': 70,
  },
  {
    'Rank': 75000,
    'Sales': 60,
  },
  {
    'Rank': 80000,
    'Sales': 50,
  },
  {
    'Rank': 85000,
    'Sales': 40,
  },
  {
    'Rank': 90000,
    'Sales': 30,
  },
  {
    'Rank': 95000,
    'Sales': 25,
  },
  {
    'Rank': 100000,
    'Sales': 22,
  },
  {
    'Rank': 110000,
    'Sales': 20,
  },
  {
    'Rank': 120000,
    'Sales': 18,
  },
  {
    'Rank': 130000,
    'Sales': 15,
  },
  {
    'Rank': 140000,
    'Sales': 12,
  },
  {
    'Rank': 150000,
    'Sales': 10,
  },
  {
    'Rank': 160000,
    'Sales': 8,
  },
  {
    'Rank': 170000,
    'Sales': 7,
  },
  {
    'Rank': 180000,
    'Sales': 6,
  },
  {
    'Rank': 190000,
    'Sales': 5,
  },
  {
    'Rank': 200000,
    'Sales': 5,
  },
  {
    'Rank': 210000,
    'Sales': 4,
  },
  {
    'Rank': 220000,
    'Sales': 4,
  },
  {
    'Rank': 230000,
    'Sales': 3,
  },
  {
    'Rank': 240000,
    'Sales': 3,
  },
  {
    'Rank': 250000,
    'Sales': 2,
  },
  {
    'Rank': 260000,
    'Sales': 2,
  },
  {
    'Rank': 270000,
    'Sales': 2,
  },
  {
    'Rank': 280000,
    'Sales': 2,
  },
  {
    'Rank': 290000,
    'Sales': 2,
  },
  {
    'Rank': 300000,
    'Sales': 2,
  },
  {
    'Rank': 350000,
    'Sales': 2,
  },
  {
    'Rank': 400000,
    'Sales': 1,
  },
  {
    'Rank': 500000,
    'Sales': 1,
  },
  {
    'Rank': 750000,
    'Sales': 0.5,
  },
  {
    'Rank': 1000000,
    'Sales': 0.5,
  },
]