import React from 'react';
import { Button, PageHeader, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import NavButton from '../NavButton';
import { useAuth } from '../../contexts/AuthContext';

const Header: React.FC = () => {
    const { logout, currentUser, userData } = useAuth();
    const history = useHistory();

    // Create a list of options in the app bar
    const signInBar = [
        <Space direction="horizontal" key="0">
            <Typography.Title
                style={{
                    marginBottom: "0",
                    color: "#fff",
                }}
                level={5}
            >
                Welcome, {currentUser?.email?.split('@')[0]}
            </Typography.Title>
            
            {userData?.role === 'ADMIN' || userData?.role === 'MANAGER' ? <NavButton url='/' name='Sourcing' key='0' /> : null}
            {userData?.role === 'ADMIN' || userData?.role === 'MANAGER' ? <NavButton url='/monitoring' name='Monitoring' key='1' /> : null}
            {userData?.role === 'ADMIN' || userData?.role === 'WHOLESALE' ? <NavButton url='/wholesale' name='Wholesale' key='2' /> : null}
            {userData?.role === 'ADMIN' ? <NavButton url='/brand' name='Brand' key='3' /> : null}
            <Button
                onClick={async () => {
                    try {
                        await logout();
                        history.push('/login');
                    } catch (e) {
                        console.log(e);
                    }
                }}
                key='4'
            >
                Sign Out
        </Button>
        </Space>,
    ];

    return (
        <PageHeader
            className='site-page-header'
            title='Project Sourcing'
            // Render options only if the user is logged in
            extra={currentUser && signInBar}
        />
    );
};

export default Header;
