import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import firebase from 'firebase/app';
import { AuthContextType, UserData } from '../types/contexts';

/**
 * Creates user's account using Firebase and his document in the users collection.
 * @param email
 * @param password
 */
async function signup(email: string, password: string) {
    let credentials = await auth.createUserWithEmailAndPassword(
        email,
        password
    );
    let user = credentials.user;

    await firestore.collection('users').doc(user?.uid).set({
        email: user?.email,
        id: user?.uid,
        role: '',
        auth_token: '',
        keepa_key: '',
        seller_id: '',
    });
    return credentials;
}

/**
 * Signs in the user using Firebase.
 * @param email
 * @param password
 * @param remember - Indicates if the session should persist after closing the tab.
 */
async function login(
    email: string,
    password: string,
    remember: boolean = false
) {
    await auth.setPersistence(
        remember
            ? firebase.auth.Auth.Persistence.LOCAL
            : firebase.auth.Auth.Persistence.SESSION
    );
    return auth.signInWithEmailAndPassword(email, password);
}

async function logout() {
    return auth.signOut();
}

/**
 * Sends an email with instructions on how to reset the password.
 * @param email
 */
function resetPassword(email: string) {
    return auth.sendPasswordResetEmail(email);
}

const AuthContext = React.createContext<AuthContextType>({
    currentUser: null,
    userData: null,
    signup,
    login,
    logout,
    resetPassword,
});

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: any }) {
    const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
    const [userData, setUserData] = useState<UserData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        });
        return unsub;
    }, []);

    useEffect(() => {
        firestore.collection('users').doc(currentUser?.uid).get().then(doc => doc.data()).then(data => setUserData(data as UserData))
    }, [currentUser])

    const value = {
        currentUser,
        userData,
        signup,
        login,
        logout,
        resetPassword,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
