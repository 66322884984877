import React, {KeyboardEvent, MouseEvent} from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const FilterDropdown: React.FC<FilterDropdownProps & {inputRef: React.Ref<Input>}> = (props: FilterDropdownProps & {inputRef: React.Ref<Input>}) => {
  const pressEnterHandler: (event: KeyboardEvent<HTMLInputElement>) => void = (event: KeyboardEvent<HTMLInputElement>) => {
    props.confirm()
  }

  const pressMouseHandler: (event: MouseEvent<HTMLElement>) => void = (event: MouseEvent<HTMLElement>) => {
    props.confirm()
  }
  
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={props.inputRef}
        placeholder="Search"
        value={props.selectedKeys[0]}
        onChange={(e) =>
          props.setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        style={{ width: 188, marginBottom: 8, display: "block" }}
        onPressEnter={pressEnterHandler}
      />
      <Space>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
          onClick={pressMouseHandler}
        >
          Search
        </Button>
        <Button onClick={props.clearFilters} style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default FilterDropdown;
