import { UploadOutlined } from "@ant-design/icons";
import { Form, Button, Input, Upload, Modal, Spin, Checkbox } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import React from "react"
import { useQueryCache } from "react-query";
import { useAuth } from "../../contexts/AuthContext";
import { importProducts, importProductsForUser } from "../../services/WholesaleService";
import ColumnSelect from "./ColumnSelect";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

interface fileMap {
    wholesale: string,
    keepa: string
}

interface columnMapping {
    upc: string,
    oh: string,
    cost?: string,
}

const WholesaleImport: React.FC = () => {
    const { currentUser } = useAuth();
    const [form] = Form.useForm();
    const [fileList, setFileList] = React.useState<RcFile[]>([])
    const [uploading, setUploading] = React.useState(false)
    const [fileMap, setFileMap] = React.useState<fileMap>({ wholesale: '', keepa: '' })
    const [wholesaleColumns, setWholesaleColumns] = React.useState<string[]>([])
    const [columnMapping, setColumnMapping] = React.useState<columnMapping>({
        upc: 'UPC',
        oh: 'OH',
        cost: 'Cost'
    })
    const queryCache = useQueryCache()

    // Prepare the columns for Wholesale Column pickers
    const reader = new FileReader()
    reader.onload = async (e) => { 
        const text = (e.target!.result as string)
        setWholesaleColumns(text!.split("\n")[0].replace(/\r|"/g, '').split(","))
    };

    // Handle the file list before uploading
    const beforeUpload = (file: RcFile, key: string) => {
        if (key === 'wholesale') {
            reader.readAsText(file)
        }

        setFileMap({ ...fileMap, [key]: file.name })
        const newFileList = [...fileList, file]
        setFileList(newFileList)
        return false;
    }
    
    const onRemove = (file: UploadFile, key: string) => {
        if (key === 'wholesale') {
            setWholesaleColumns([])
        }

        setFileMap({ ...fileMap, [key]: ''})
        const newFileList = fileList.filter(el => el.uid !== file.uid)
        setFileList(newFileList)
    }


    const onFinish = (values: any) => {
        setUploading(true)
        const collection = form.getFieldValue("collection").replace(/_/g, "")
        const uploadForAll = form.getFieldValue("uploadForAll")
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        formData.append('wholesale', fileMap.wholesale);
        formData.append('keepa', fileMap.keepa);
        formData.append('columnsMapping', JSON.stringify(columnMapping));

        let respStatus = -1;
        
        const handleRes = (res: any) => {
            setUploading(false)
            if (respStatus === 200) {
                queryCache.invalidateQueries('collections')
                Modal.success({
                    content: `Successfully uploaded data to collection ${collection}!`,
                });
            } else {
                Modal.error({
                    title: `${res.msg}`,
                    content: `${res.error}`,
                });
            }
        }

        if (uploadForAll) {
            importProducts(collection, formData).then(res => {
                respStatus = res.status
                return res.json();
            }).then((res) => handleRes(res))
        } else {
            currentUser?.getIdToken().then((token) => {
                console.log('Token', token)
                importProductsForUser(collection, formData, token).then(res => {
                    respStatus = res.status
                    return res.json();
                }).then((res) => handleRes(res))
            })
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Spin spinning={uploading}>
            <Form
                {...layout}
                form={form}
                name="wholesaleForm"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Collection"
                    name="collection"
                    rules={[{ required: true, message: 'Please input collection name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="wholesaleData"
                    label="Wholesale Data"
                    valuePropName="wholesaleDataFile"
                    extra={
                        <div>
                            <b>Required columns:</b>
                            <br/>
                            UPC <ColumnSelect columns={wholesaleColumns} onChange={(val) => setColumnMapping((prev) => ({...prev, 'upc': val}))}></ColumnSelect>
                            <br/>
                            OH (OnHand) <ColumnSelect columns={wholesaleColumns} onChange={(val) => setColumnMapping((prev) => ({...prev, 'oh': val}))}></ColumnSelect>
                            <br/>
                            <b>Additional columns:</b>
                            <br/>
                            Cost <ColumnSelect columns={wholesaleColumns} onChange={(val) => setColumnMapping((prev) => ({...prev, 'cost': val}))}></ColumnSelect>
                        </div>
                    }
                >
                    <Upload beforeUpload={(file: RcFile) => beforeUpload(file, 'wholesale')} onRemove={(file: UploadFile) => onRemove(file, 'wholesale')} accept={'.tsv, .csv'} name="wholesaleData" listType="text">
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    name="keepaData"
                    label="Keepa Data"
                    valuePropName="keepaDataFile"
                    extra={<>
                        <b>Required columns:</b>
                        <br/>ASIN
                        <br/>Title
                        <br/>Sales Rank: Current
                        <br/>Sales Rank: 30 days avg.
                        <br/>Sales Rank: 90 days avg.
                        <br/>New: Current
                        <br/>Amazon: Current
                        <br/>List Price: Current
                        <br/>Buy Box 🚚: Current
                        <br/>Buy Box 🚚: 30 days avg.
                        <br/>Buy Box 🚚: 90 days avg.
                        <br/>Product Group
                        <br/>Reviews: Review Count
                        <br/>FBA Pick&Pack Fee
                        <br/>Product Codes: UPC
                        <br/>Parent ASIN
                        <br/>Variation ASINs
                        <br/>Categories: Root
                        <br/>Package: Weight (g)
                    </>}
                >
                    <Upload beforeUpload={(file: RcFile) => beforeUpload(file, 'keepa')} onRemove={(file: UploadFile) => onRemove(file, 'keepa')} accept={'.tsv, .csv'} name="keepaData" listType="text">
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="Upload for everyone"
                    name="uploadForAll"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit
                </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}

export default WholesaleImport