import { Button, Divider, Modal, Space } from "antd"
import React, { useEffect, useState } from "react"
import { useQuery, useQueryCache } from "react-query"
import { useAuth } from "../../contexts/AuthContext"
import { compareSupplierValues, deleteCollection, getCollections, getCollectionsForUser, getInventory, getProducts } from "../../services/WholesaleService"
import { InventoryItem, WholesaleItem } from "../../types/WholesaleItem"
import CollectionsDropdown from "../CollectionsDropdown"
import ExperimentalTable from "./ExperimentalTable"

const WholesaleTab: React.FC<{saved: boolean, name: string}> = ({saved, name}) => {
    const { currentUser, userData } = useAuth();
    const [collection, setCollection] = useState<string>("");
    const [tableData, setTableData] = useState<WholesaleItem[]>([]);
    // const [experimentalTable, setExperimentalTable] = useState(true)
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const queryCache = useQueryCache();
    const mainData = useQuery(['wholesale', collection, name], (_, collection: string) =>
        collection ? getProducts(collection) : []
    , {staleTime: Infinity});

    const savedCollection = collection.split("_")[0] + "_saved"
    const savedData = useQuery(['wholesale', savedCollection, name], (_, collection: string) =>
        savedCollection ? getProducts(collection) : []
    , {staleTime: Infinity});

    const updateTableData = (products: WholesaleItem[]) => {
        setTableData(products)
    }

    useEffect(() => {
        queryCache.invalidateQueries(['wholesale', collection, name]);
    }, [collection, name, queryCache])

    // Use the saved collection data to overwrite initial item values, if they exist
    // Append inventory levels data
    useEffect(() => {
        if (mainData.data && savedData.data && collection !== "") {
            const newTableData: WholesaleItem[] = mainData.data.map(prod => ({...prod, saved: false}))

            // Update the item values
            savedData.data.forEach(prod => {
                newTableData.filter(tableProd => tableProd.ASIN === prod.ASIN && compareSupplierValues(tableProd, prod)).forEach((row, idx) => {
                    Object.entries(prod).forEach(([property, value]) => {
                        row[property] = value;
                    })
                    row.saved = true;
                })
            })

            // Get inventory data
            currentUser?.getIdToken().then(token => {
                getInventory(token).then((inventoryData: InventoryItem[]) => {
                    const inventoryMap: Map<string, InventoryItem> = new Map()
                    inventoryData.forEach((inventoryItem) => inventoryMap.set(inventoryItem.ASIN, inventoryItem))

                    newTableData.forEach((wholesaleItem) => {
                        const inventoryItem = inventoryMap.get(wholesaleItem.ASIN)
                        if (inventoryItem) {
                            wholesaleItem.InvQuantity = inventoryItem.TotalQuantity;
                        } else {
                            wholesaleItem.InvQuantity = -1;
                        }
                    })

                    // Update the state
                    updateTableData(newTableData)
                })
            })
        }
    }, [mainData, savedData, collection, currentUser])

    // Handle the Spinner state
    useEffect(() => {
        if (!mainData.isLoading && !savedData.isLoading) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [mainData, savedData])

    // Handle deleting the collection
    const handleDelete = (collection: string) => {
        deleteCollection(collection).then(res => {
            if (res.error === null) {
                queryCache.invalidateQueries(['collections']);
                queryCache.invalidateQueries(['wholesale', collection, name]);
                updateTableData([])
                Modal.success({
                    content: `Successfully deleted collection ${collection}!`,
                });
            } else {
                Modal.error({
                    title: `${res.msg}`,
                    content: `${res.error}`,
                });
            }
        })
    }

    useEffect(() => {
        queryCache.invalidateQueries(['collections', name]);
    }, [showAll, queryCache, name])

    return (
        <>
            <div style={{ display: "flex" }}>
                <CollectionsDropdown queryKey={name} onChange={(x) => setCollection(x)} getCollections={showAll ? () => getCollections(saved) : () => getCollectionsForUser(saved, currentUser!)} />
                <Button type="primary" onClick={() => setShowAll((show) => !show)}>{showAll ? 'Show yours' : 'Show all'}</Button>
                {userData?.role === 'ADMIN' ? <Space style={{marginLeft: '8px'}}>
                    <Button type="primary" danger onClick={() => handleDelete(collection)}>Delete</Button> 
                    {/* <Button type="primary" danger onClick={() => setExperimentalTable((table) => !table)}>Experiment</Button> */}
                </Space> : null}
            </div>
            <Divider />
            <ExperimentalTable save={!saved} isLoading={isLoading} data={tableData} collection={collection} />
        </>
    )
}

export default WholesaleTab;