import React from "react"
import { Col, Row, Tabs } from "antd";
import WholesaleImport from "../components/wholesale/WholesaleImport";
import WholesaleTab from "../components/wholesale/WholesaleTab";
import { useQueryCache } from "react-query";
import ReviewGrab from "../components/wholesale/ReviewGrab";
const { TabPane } = Tabs;

const Wholesale: React.FC = () => {
    const queryCache = useQueryCache()

    const resetQueries = () => {
        console.log('Invaliding!')
        queryCache.invalidateQueries("wholesale")
    }

    return (
        <Row>
            <Col span={24}>
                <Tabs onChange={resetQueries} defaultActiveKey="2">
                    <TabPane tab="Import" key="1">
                        <WholesaleImport></WholesaleImport>
                    </TabPane>
                    <TabPane tab="Browse all" key="2">
                        <WholesaleTab name={'All'} saved={false}></WholesaleTab>
                    </TabPane>
                    <TabPane tab="Saved" key="3">
                        <WholesaleTab name={'Saved'} saved={true}></WholesaleTab>
                    </TabPane>
                    {/* <TabPane tab="Reviews" key="4">
                        <ReviewGrab></ReviewGrab>
                    </TabPane> */}
                </Tabs>
            </Col>
            
        </Row>
    )
}

export default Wholesale;