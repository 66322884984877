import React, { useState, ReactNode, MouseEvent } from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Slider, Space, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

interface SliderFilterDropdownProps {
  name: string;
  min?: number;
  max?: number;
  tipFormatter?: (value: number | undefined) => ReactNode;
}

const SliderFilterDropdown: React.FC<
  FilterDropdownProps & SliderFilterDropdownProps
> = ({ min = 0, max = 100, tipFormatter, ...props }) => {
  const pressMouseHandler: (event: MouseEvent<HTMLElement>) => void = (event: MouseEvent<HTMLElement>) => {
    props.confirm()
  }

  const [range, setRange] = useState<[number, number]>([min, max]);
  const onChange = (value: [number, number]) => {
    if (value) {
      props.setSelectedKeys([value[0] + " " + value[1]]);
      setRange(value);
    }
  };
  const reset = () => {
    props.clearFilters && props.clearFilters();
    setRange([min, max]);
  };
  return (
    <div style={{ padding: 8 }}>
      {props.name} range:
      <Slider range min={min} max={max} onChange={onChange} value={range} tipFormatter={tipFormatter} />
      <Space>
        <Button
          type="primary"
          icon={<FilterOutlined />}
          size="small"
          style={{ width: 90 }}
          onClick={pressMouseHandler}
        >
          Filter
        </Button>
        <Button onClick={reset} style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default SliderFilterDropdown;
