import { InfoCircleFilled, WarningFilled } from "@ant-design/icons";
import { Button, Divider, Form, Input, message, Popover, Space, Spin, Table, Tag, Typography } from "antd";
import { FormInstance } from 'antd/lib/form';
import { ColumnsType, ColumnType } from "antd/lib/table";
import { FilterDropdownProps } from "antd/lib/table/interface";
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import ReactDragListView from 'react-drag-listview';
import { saveProduct, SUPPLIER_PREFIX, updateProduct } from "../../services/WholesaleService";
import { KeepaItem, KeepaMultiplierItem, WholesaleItem } from "../../types/WholesaleItem";
import FilterDropdown from "../FilterDropdown";
import { getBooleanFilter, getNumberFilterSorter, getNumberRenderer, getPriceRenderer, getSellPriceRenderer } from "../utilities/TableFilterSorters";
import EstSalesPopover from "./EstSalesPopover";
import { ExcelDownload } from "./ExcelDownload";
import { FindBuyQTY, FindEstSales, FindSales, FindSellPrice, RecalculateProfits } from "./ItemCalcs";
import ProfitPopover from "./ProfitPopover";
const { Link } = Typography;
const { TextArea } = Input;

const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof WholesaleItem;
    record: WholesaleItem;
    handleSave: (record: WholesaleItem) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<Input>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: !Number.isNaN(record[dataIndex]) ? (Number.isInteger(record[dataIndex]) ? record[dataIndex].toFixed(0) : record[dataIndex].toFixed(2)) : record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};

interface DataType extends WholesaleItem {
    key: React.Key;
}

function search(dataIndex: string, value: any, record: WholesaleItem) {
    return record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toString().toLowerCase());
}

function getSearch(dataIndex: string) {
    return (value: any, record: WholesaleItem) => search(dataIndex, value, record)
}

const CELL_COLORS = {
    'BLUE': '#97f2f3',
    'YELLOW': '#fcf0cf',
    'GREEN': '#D7e7a9',
    'RED': '#FA6E4F',
}

const ExperimentalTable: React.FC<{ isLoading: boolean, data: WholesaleItem[], save?: boolean, collection: string; }> = ({ isLoading, data, save = false, collection }) => {
    const [columns, setColumns] = useState<ColumnsType<DataType>>([])
    const [finalColumns, setFinalColumns] = useState<ColumnsType<DataType>>([])
    const [filteredData, setFilteredData] = useState<WholesaleItem[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(['UPC', 'Parent', 'QTY', 'Cost', 'Inv', 'Comments', 'Reviews'])
    const searchInputRef = useRef<Input>(null);

    function initTableData(data: WholesaleItem[]) {
        const transformedData = data?.map((el, idx) => {
            const row = {
                ...el, 
                key: idx, 
                Profit: el.Profit || -1, cost: el.cost || -1,
                AskPrice: el.AskPrice || el.cost || -1,
                sellPrice: el.sellPrice || -1,
                sizeMultiplier: el.sizeMultiplier || 1,
                colorMultiplier: el.colorMultiplier || 1,
                reviewMultiplier: el.reviewMultiplier || 1,
                bbMultiplier: el.bbMultiplier || -1,
                SuggestedQTY: el.SuggestedQTY || el.BuyQTY || 0,
                variationReviews: el.variationReviews || -1,
                variationReviewsTotal: el.variationReviewsTotal || -1,
                variationActive: el.variationActive || -1,
                variationChecked: el.variationChecked || (el.variationReviews > -1) || false,
                recentlyEdited: false,
                comments: el.comments || '',
            }

            if (row.bbMultiplier === -1) {
                if (row["Buy Box Seller"] === "Amazon") {
                    row.bbMultiplier = 0.25
                } else if (row.currentBuyBoxPrice >= 0) {
                    if (row["Buy Box: Is FBA"] === "yes") {
                        row.bbMultiplier = 0.50
                    } else if (row["Buy Box: Is FBA"] === "no") {
                        row.bbMultiplier = 0.75
                    } else {
                        row.bbMultiplier = 0.50
                    }
                } else {
                    row.bbMultiplier = 1
                }
            }
            
            if (row.sellPrice === -1) {
                FindSellPrice(row);
            }

            if (!row.calcRank || row.calcRank === -1) {
                FindSales(row);
            }
            
            FindEstSales(row);
            RecalculateProfits(row);
            FindBuyQTY(row);

            row.BuyQTY = row.BuyQTY > -1 ? row.BuyQTY : Math.min(row.onHand, row.SuggestedQTY);

            return row
        })

        setFilteredData(transformedData);
        console.log('Transformed', transformedData)
        return transformedData;
    }

    function handleUpdate(state: DataType[], row: DataType | WholesaleItem[]) {
        if (!Array.isArray(row)) {
            const updateNewData = (newData: DataType[], row: DataType) => {
                const index = newData.findIndex(item => row.key === item.key);
                const item = newData[index];
                
                // Update the price-related calculations
                RecalculateProfits(row);
                FindEstSales(row);
                FindBuyQTY(row);
        
                // Limit the BuyQTY to what's available
                if (row.BuyQTY > row.onHand) {
                    row.BuyQTY = Number(row.onHand)
                }
        
                // Prevent the edited item from disappearing from filters
                newData.forEach(item => item.recentlyEdited = false);
                row.recentlyEdited = true;
        
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    editSiblings: false,
                });
        
                setFilteredData(() => newData);
                console.log('Saving')
            }

            if (row.editSiblings) {
                const newCalcRank = row.calcRank;
                const parent = row.parentASIN;
                const newData = [...state];
                for (const item of newData) {
                    if (item.parentASIN === parent) {
                        updateNewData(newData, {...item, calcRank: newCalcRank, editSiblings: false})
                    }
                }

                return newData
            } else {
                const newData = [...state];
                updateNewData(newData, row)
                return newData
            }
        } else {
            return initTableData(row)
        }
    }
    
    const [tableData, updateTableData] = useReducer(handleUpdate, data, initTableData);

    useEffect(() => {
        updateTableData(data)
    }, [data])

    const handleCommentEdit = useCallback((row: DataType, event: any) => {
        if (event) {
            row.comments = event.target.value
        }
    }, [])

    useEffect(() => {
        const handleKeepaData = (data: KeepaItem[]) => {
            let activeVars = 0;
            let totalReviews = 0;
            let totalRatings = 0;
            let totalRR = 0;
            
            // TALK ABOUT THIS
            data.forEach(item => {
                if (item.Offers > 0) {
                    activeVars++;
                    totalRatings += item.Ratings;
                    totalReviews += item.Reviews;
                    totalRR += (item.Ratings + item.Reviews);
                }
            })

            const parsedData: KeepaMultiplierItem[] = data.map(item => (
                {
                    ...item, 
                    reviewMultiplier: item.Reviews / (totalReviews || 1), 
                    ratingMultiplier: item.Ratings / (totalRatings || 1),
                    totalMultiplier: (item.Reviews + item.Ratings) / ((totalReviews + totalRatings) || 1),
                    RR : item.Reviews + item.Ratings,
                }
            ))

            const parsedMap = Object.fromEntries(parsedData.map(item => ([item.ASIN, item])))
            console.log(parsedMap);
            console.log(totalRR);

            const updateItems: WholesaleItem[] = [];
            tableData.forEach(item => {
                if (parsedMap[item.ASIN] && item.saved === false) {
                    if (parsedMap[item.ASIN].Offers > 0) {
                        item.reviewMultiplier = parsedMap[item.ASIN].totalMultiplier;
                    }
                    item.variationReviewsTotal = totalRR;
                    item.activeOffers = parsedMap[item.ASIN].Offers;
                    item.variationActive = activeVars;
                    item.variationReviews = parsedMap[item.ASIN].RR;
                    item.variationChecked = true;
                    console.log(item);
                    updateItems.push(item);
                    updateTableData(item);
                }
            })
            
            updateProduct(updateItems);
        }

        const handleWholesaleMessage = (event: MessageEvent) => {
            const eventMessage = event.data
            if (eventMessage.action === 'extracted_keepa_data_for_site') {
                console.log('Received Variation Data Messege from Extension!', eventMessage);
                if (eventMessage.keepaData.length > 0) {
                    message.success('Variation data grabbed!');
                } else {
                    message.error('Received empty variation data! Check the tab and retry if needed.');
                }
                handleKeepaData(eventMessage.keepaData);
            } else if (eventMessage.action === 'extract_started') {
                message.info('Variation data is being grabbed!')
            }
        }

        console.log('Setting listener for messages!');
        window.addEventListener('message', handleWholesaleMessage);
        
        // cleanup this component
        return () => {
            console.log('Removing listener for messages!');
            window.removeEventListener('message', handleWholesaleMessage);
        };
    });

    useEffect(() => {
        const localColumns: ColumnsType<DataType> = [
            {
                title: 'ASIN',
                dataIndex: 'ASIN',
                key: 'ASIN',
                width: "135px",
                fixed: "left",
                filterDropdown: (props: FilterDropdownProps) => (
                    <FilterDropdown {...props} inputRef={searchInputRef} />
                  ),
                onFilter: getSearch('ASIN'),
                onFilterDropdownVisibleChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
                render: (text: string, record: WholesaleItem) => (
                    <Space size="small" align="baseline">
                        <Link href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                            {text}
                        </Link>
                        <Divider type="vertical"/>
                        <Link href={`https://sellercentral.amazon.com/product-search/search?q=${text}`} target="_blank">
                            <WarningFilled style={{color: 'red'}} />
                        </Link>
                    </Space>
                ),
            },
            {
                title: 'UPC',
                dataIndex: 'UPC',
                key: 'UPC',
                width: "110px",
                fixed: "left",
                filterDropdown: (props: FilterDropdownProps) => (
                    <FilterDropdown {...props} inputRef={searchInputRef} />
                  ),
                onFilter: getSearch('UPC'),
                onFilterDropdownVisibleChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
                render: (text: string, record: WholesaleItem) => text,
            },
            {
                title: 'Parent',
                dataIndex: 'parentASIN',
                key: 'parentASIN',
                width: "110px",
                sorter: {
                    compare: (a, b) => a.parentASIN > b.parentASIN ? 1 : (a.parentASIN === b.parentASIN ? 0 : -1),
                    multiple: 2
                },
                filterDropdown: (props: FilterDropdownProps) => (
                    <FilterDropdown {...props} inputRef={searchInputRef} />
                  ),
                onFilter: getSearch('parentASIN'),
                onFilterDropdownVisibleChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
                render: (text: string, record: WholesaleItem) => text,
            },
            {
                title: 'Reviews',
                key: 'reviewCount',
                dataIndex: 'reviewCount',
                width: "85px",
                ...getNumberFilterSorter('reviewCount')
            },
            {
                title: 'Title',
                dataIndex: 'name',
                key: 'name',
                width: "250px",
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                    multiple: 1
                },
                filterDropdown: (props: FilterDropdownProps) => (
                    <FilterDropdown {...props} inputRef={searchInputRef} />
                  ),
                onFilter: getSearch('name'),
                onFilterDropdownVisibleChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
            },
            {
                title: 'SellPrice',
                dataIndex: 'sellPrice',
                key: 'sellPrice',
                width: "110px",
                ...getNumberFilterSorter('sellPrice'),
                ...getSellPriceRenderer(),
            },
            {
                title: 'Cost',
                key: 'cost',
                dataIndex: 'cost',
                width: "85px",
                ...getNumberFilterSorter('cost')
            },
            {
                title: 'Profit',
                key: 'Profit',
                dataIndex: 'Profit',
                width: "85px",
                ...getNumberFilterSorter('Profit'),
                render: (val, record) => <Popover id="wholesale-popover" autoAdjustOverflow={true} content={ProfitPopover(record)} trigger="click">
                    <Typography>{`$${val.toFixed(2)}`}</Typography>
                </Popover>,
                onCell: (record) => {
                    const style = {backgroundColor: 'white'};

                    if ((record.productGroup === 'Shoes' && record.Profit > 5) || (record.productGroup !== 'Shoes' && record.Profit > 2.5) ) {
                        style.backgroundColor = CELL_COLORS.GREEN
                    } else {
                        style.backgroundColor = CELL_COLORS.RED
                    }

                    if (record.currentBuyBoxPrice <= 0) {
                        style.backgroundColor = CELL_COLORS.BLUE
                    }
                    
                    return { style };
                },
            },
            {
                title: 'ROI',
                key: 'ROI2',
                dataIndex: 'ROI2',
                width: "85px",
                ...getNumberFilterSorter('ROI2'),
                render: (val, record) => <Popover id="wholesale-popover" autoAdjustOverflow={true} content={ProfitPopover(record)} trigger="click">
                    <Typography>{`${val.toFixed(2)}%`}</Typography>
                </Popover>,
                onCell: (record) => {
                    const style = {backgroundColor: 'white'};

                    if (record.ROI2 < 20) {
                        style.backgroundColor = CELL_COLORS.RED
                    } else if (record.ROI2 < 35) {
                        style.backgroundColor = CELL_COLORS.YELLOW
                    } else {
                        style.backgroundColor = CELL_COLORS.GREEN
                    }

                    if (record.currentBuyBoxPrice <= 0) {
                        style.backgroundColor = CELL_COLORS.BLUE
                    }
                    
                    return { style };
                },
            },
            {
                title: 'EstSales',
                key: 'EstSales',
                dataIndex: 'EstSales',
                width: "110px",
                ...getNumberFilterSorter('EstSales'),
                render: (val, record) => {
                    const salesRanks = [record.currentSalesRank, record.salesRank30Avg, record.salesRank90Avg];
                    const minRank = Math.min(...salesRanks);
                    const maxRank = Math.max(...salesRanks);
                    
                    return <Popover
                        id="wholesale-popover"  
                        autoAdjustOverflow={true}
                        key={record.ASIN} 
                        destroyTooltipOnHide={true}
                        content={EstSalesPopover({record, handleSave: updateTableData})} 
                        trigger="click"
                        title={
                            maxRank / minRank  >= 3 ? 
                            <><WarningFilled style={{color: 'red'}} /> High variance detected <WarningFilled style={{color: 'red'}} /></> 
                            : 
                            null
                        }
                    >
                    <Typography>{`${val.toFixed(2)}`} {maxRank / minRank  >= 3 ? <WarningFilled style={{color: 'red'}} /> : null}</Typography>
                </Popover>},
                onCell: (record) => {
                    const style = {backgroundColor: 'white'};

                    if (record.reviewMultiplier < 1 || record.variationReviewsTotal > -1) {
                        style.backgroundColor = CELL_COLORS.GREEN
                    } else if (record.reviewMultiplier > 1) {
                        style.backgroundColor = CELL_COLORS.YELLOW
                    } 
                    
                    if (record.activeOffers === 0) {
                        style.backgroundColor = CELL_COLORS.BLUE
                    }
                    
                    return { style };
                },
            },
            {
                title: 'ToBuy',
                key: 'SuggestedQTY',
                dataIndex: 'SuggestedQTY',
                width: "110px",
                ...getNumberFilterSorter('SuggestedQTY')
            },
            {
                title: 'VarCheck',
                key: 'variationChecked',
                dataIndex: 'variationChecked',
                width: "90px",
                render: (val) => val ? 'True' : 'False',
                ...getBooleanFilter('variationChecked')
            },
            {
                title: 'BuyQTY',
                key: 'BuyQTY',
                dataIndex: 'BuyQTY',
                width: "110px",
                ...getNumberFilterSorter('BuyQTY'),
                render: (val, record) => {
                    if (val >= 5 || val * record.sellPrice >= 200) {
                        return <Popover 
                            id="wholesale-popover" 
                            content={"This item requires further review"} 
                            trigger="hover" 
                            title={null}
                        >
                            <Space direction="horizontal" align="center">
                                <Typography>
                                    {val} 
                                </Typography>
                                <InfoCircleFilled style={{color: CELL_COLORS.RED}} />
                            </Space>
                        </Popover>
                    } else {
                        return val
                    }
                },
            },
            {
                title: 'OH',
                dataIndex: 'onHand',
                key: 'onHand',
                width: "85px",
                ...getNumberFilterSorter('onHand'),
                ...getNumberRenderer()
            },
            {
                title: 'Inv',
                dataIndex: 'InvQuantity',
                key: 'InvQuantity',
                width: "85px",
                ...getNumberFilterSorter('InvQuantity'),
                ...getNumberRenderer()
            },
            {
                title: 'AskPrice',
                key: 'AskPrice',
                dataIndex: 'AskPrice',
                width: "110px",
                ...getNumberFilterSorter('AskPrice'),
                ...getPriceRenderer()
            },
            {
                title: 'Keepa',
                key: 'keepaGraph',
                dataIndex: 'keepaGraph',
                width: "325px",
                render: (text: string, record: DataType) => {
                    return <a target="_blank" rel="noopener noreferrer" href={`https://keepa.com/#!product/1-${record.ASIN}`}><img src={`https://graph.keepa.com/pricehistory.png?domain=com&salesrank=1&asin=${record.ASIN}&range=365`} height={125} width={325} alt='Keepa graph'/></a>
                }
            },
            {
                title: 'Comments',
                key: 'comments',
                dataIndex: 'comments',
                width: "325px",
                render: (text: string, record: DataType) => {
                    return <TextArea defaultValue={text} onChange={(text) => handleCommentEdit(record, text)}></TextArea>
                }
            },
            {
                title: 'Save',
                key: 'save',
                fixed: 'right',
                width: "100px",
                render: (text: string, record: DataType) => (
                    <Space direction="vertical" size="middle" align="center" style={{ width: "100%" }}>
                        {
                        record.saved ? 
                        <Tag style={{marginRight: 0, background: CELL_COLORS.GREEN}}>Saved</Tag> 
                        :
                        null
                        } 
                        <Button onClick={() => {
                            saveProduct({...record, saved: true}).then(res => res.json()).then(res => {
                                if (res.error === null) {
                                    message.success('Saved!')
                                    record.saved = true;
                                    updateTableData(record)
                                } else {
                                    message.error(res.error)
                                }
                            })
                        }}>Save</Button>
                    </Space>
                )
            },
        ]

        // Supplier (custom) columns from the WS file will go in the space between Keepa and Comments, so we're adding them here
        if(data.length > 0) {
            const supplierColumnNames = Object.keys(data[0]).filter(col => col.includes(SUPPLIER_PREFIX))
            const supplierColumns: ColumnsType<DataType> = supplierColumnNames.map((colName) => ({
                title: colName.split("_")[1],
                key: colName,
                dataIndex: colName,
                width: "120px",
                sorter: {
                    compare: (a, b) => a[colName] > b[colName] ? 1 : (a[colName] === b[colName] ? 0 : -1),
                    multiple: 3
                },
                filterDropdown: (props: FilterDropdownProps) => (
                    <FilterDropdown {...props} inputRef={searchInputRef} />
                  ),
                onFilter: getSearch(colName),
                onFilterDropdownVisibleChange: (visible: boolean) =>
                visible && setTimeout(() => searchInputRef.current?.select()),
            }))

            setHiddenColumns((hiddenColumns) => hiddenColumns.concat(supplierColumnNames.map((colName) => colName.split("_")[1])));
            localColumns.splice(-2, 0, ...supplierColumns);
        }

        setColumns(localColumns)
    }, [setColumns, handleCommentEdit, data])

    useEffect(() => {
        const editableColumns = ['AskPrice', 'SellPrice', 'BuyQTY']
        columns.forEach((column: ColumnType<DataType>) => {
            if (editableColumns.includes(column.title as string)) {
                if (column.title === 'SellPrice') {
                    column.onCell = (record: DataType) => {
                        const style = {backgroundColor: 'white'};
                    
                        if (record.currentBuyBoxPrice > 0) {
                            style.backgroundColor = CELL_COLORS.GREEN
                        }
    
                        if (record["Buy Box Seller"] === 'Amazon') {
                            style.backgroundColor = CELL_COLORS.YELLOW
                        }
    
                        if (record.currentBuyBoxPrice <= 0) {
                            style.backgroundColor = CELL_COLORS.BLUE;
                        }

                        return {
                            record,
                            dataIndex: column.dataIndex,
                            title: column.title as string,
                            editable: true,
                            handleSave: (record: DataType) => updateTableData(record),
                            style,
                        }
                    }
                } else {
                    column.onCell = (record: DataType) => ({
                        record,
                        dataIndex: column.dataIndex,
                        title: column.title as string,
                        editable: true,
                        handleSave: (record: DataType) => updateTableData(record),
                    })
                }
            } 
        })

        setFinalColumns(columns);
    }, [columns])

    const dragProps = {
        onDragEnd(fromIndex: any, toIndex: any) {
            const localColumns: any = [...columns as any];
            const item = localColumns.splice(fromIndex, 1)[0];
            localColumns.splice(toIndex, 0, item);
            setColumns(localColumns)
        },
        nodeSelector: "button"
    };

    console.log('Re-rendering the table view')

    return (
        <Spin spinning={isLoading}>
            <ReactDragListView.DragColumn {...dragProps}>
            <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                {columns.map(column => {
                    if (hiddenColumns.includes(column.title as string)) {
                        return <Button style={{marginBottom: '3px'}} size="small" key={column.title as string} type="dashed" onClick={() => setHiddenColumns((hidden) => hidden.filter(col => col !== column.title))}>{column.title}</Button>
                    } else {
                        return <Button style={{marginBottom: '3px'}} size="small" key={column.title as string} type="primary" onClick={() => setHiddenColumns((hidden) => hidden.concat([column.title as string]))}>{column.title}</Button>
                    }
                })}
            </div>
            </ReactDragListView.DragColumn>
            <Divider />
            <Table<DataType>
                components={
                    {
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        }
                    }
                }
                rowClassName={() => 'editable-row'}
                scroll={{ y: "75vh", x: "max-content" }}
                sticky={true}
                bordered={true}
                size="small"
                tableLayout={"fixed"}
                title={() => <ExcelDownload
                    data={filteredData}
                    isLoading={isLoading}
                    collection={collection}
                />}
                dataSource={tableData}
                columns={finalColumns.filter((column) => !hiddenColumns.includes(column.title as string))}
                onChange={(pagination, filters, sorter, extra) => {
                    console.log('Changing data!');
                    // setFilteredData(extra.currentDataSource)
                }}
            >
            </Table>
        </Spin>
    )
}

export default ExperimentalTable