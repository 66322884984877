import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import * as dataForge from "data-forge"

export interface CSVDownloadProps {
  data: any[] | null | undefined;
  isLoading: boolean;
  collection: string;
}

export function CSVDownload({ data, isLoading, collection }: CSVDownloadProps) {
  const download = () => {
    if (!data || !data.length) return;

    let headers: string[] = [];

    data.forEach((obj: any) => {
      Object.keys(obj).forEach((key) => {
        if (!headers.includes(key) && key !== "key") {
          headers.push(key);
        }
      });
    });

    const text = (new dataForge.DataFrame(data)).toCSV()

    const file = new Blob([text], { type: "text/plain;charset=utf-8" });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = collection + ".csv";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
  };

  return (
    <Button
      type="primary"
      onClick={download}
      disabled={!data || data.length === 0 || isLoading}
    >
      <DownloadOutlined /> Download CSV
    </Button>
  );
}
