import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import * as dataForge from "data-forge"
import { CSVDownloadProps } from "../CSVDownload";
import { WholesaleItem } from "../../types/WholesaleItem";
import ExcelJS from "exceljs"
import { SUPPLIER_PREFIX } from "../../services/WholesaleService";

const PrepData = (data: WholesaleItem[]) => {
  const globalFrame = new dataForge.DataFrame(data);

  const supplierColumns = globalFrame.getColumnNames().filter((name) => name.includes(SUPPLIER_PREFIX));
  const firstSheetData = globalFrame
    .subset(['ASIN', 'name', 'sellPrice', 'BuyQTY', 'AskPrice'])
    .generateSeries({
      MinPrice: (row) => row.sellPrice > 0 ? parseFloat((row.sellPrice * 0.8).toFixed(2)) : 0,
      MaxPrice: (row) => row.sellPrice > 0 ? parseFloat((row.sellPrice * 1.5).toFixed(2)) : 0,
    })
    .transformSeries({
      AskPrice: (val) => val > 0 ? val : 'Manual Input Needed',
    })
    .dropSeries('sellPrice')
    .renameSeries({
      'name': 'Title',
      'BuyQTY': 'Quantity',
      'AskPrice': 'Cost',
    });

  const secondSheetData = globalFrame;

  const thirdSheetData = globalFrame
    .subset(['UPC', 'BuyQTY', 'AskPrice', ...supplierColumns])
    .renameSeries({
      'BuyQTY': 'Quantity',
    });

  const workbook = new ExcelJS.Workbook();
  const firstSheet = workbook.addWorksheet('Buy Sheet');
  firstSheet.columns = firstSheetData.getColumnNames().map((column) => ({ header: column, key: column }));
  const secondSheet = workbook.addWorksheet('Purchase Data');
  secondSheet.columns = secondSheetData.getColumnNames().map((column) => ({ header: column, key: column }));
  const thirdSheet = workbook.addWorksheet('Offer Data');
  thirdSheet.columns = thirdSheetData.getColumnNames().map((column) => ({ header: column, key: column }));

  firstSheet.addRows(firstSheetData.toArray());
  secondSheet.addRows(secondSheetData.toArray());
  thirdSheet.addRows(thirdSheetData.toArray());

  return workbook;
}

export function ExcelDownload({ data, isLoading, collection }: CSVDownloadProps) {
  const download = async () => {
    if (!data || !data.length) return;

    let headers: string[] = [];

    data.forEach((obj: any) => {
      Object.keys(obj).forEach((key) => {
        if (!headers.includes(key) && key !== "key") {
          headers.push(key);
        }
      });
    });

    const workbook = PrepData(data);
    const text = await workbook.xlsx.writeBuffer();

    const file = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = collection + ".xlsx";
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode?.removeChild(a);
  };

  return (
    <Button
      type="primary"
      onClick={download}
      disabled={!data || data.length === 0 || isLoading}
    >
      <DownloadOutlined /> Download XLSX
    </Button>
  );
}
