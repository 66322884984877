import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Header from './components/navigation/Header';
import Sourcing from './routes/Sourcing';
import Monitoring from './routes/Monitoring';
import Wholesale from './routes/Wholesale';

import './App.css';
import { Layout } from 'antd';
import Login from './routes/auth/Login';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import ForgotPassword from './routes/auth/ForgotPassword';
import Brand from './routes/Brand';
const { Content } = Layout;

function App() {
    return (
        <Router>
            <AuthProvider>
                <Header />
                <Layout className='layout'>
                    <Content
                        style={{
                            padding: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            className='site-layout-content'
                            style={{ flexBasis: '90%', maxWidth: '100%' }}
                        >
                            <Switch>
                                <PrivateRoute desiredRole="MANAGER" exact path='/sourcing'>
                                    <Sourcing />
                                </PrivateRoute>

                                <PrivateRoute desiredRole="MANAGER" exact path='/monitoring'>
                                    <Monitoring />
                                </PrivateRoute>

                                <PrivateRoute desiredRole="WHOLESALE" exact path='/wholesale'>
                                    <Wholesale />
                                </PrivateRoute>

                                <PrivateRoute desiredRole="ADMIN" exact path='/brand'>
                                    <Brand />
                                </PrivateRoute>

                                <Route exact path='/login'>
                                    <Login />
                                </Route>

                                {/* <Route exact path='/signup'>
                                    <Signup />
                                </Route> */}
                                <Route exact path='/forgot-password'>
                                    <ForgotPassword />
                                </Route>

                                <PrivateRoute desiredRole="MANAGER" path='/'>
                                    <Sourcing />
                                </PrivateRoute>
                            </Switch>
                        </div>
                    </Content>

                    {/* <Footer style={{ textAlign: 'center' }}>
                        Project Suite
                    </Footer> */}
                </Layout>
            </AuthProvider>
        </Router>
    );
}

export default App;
